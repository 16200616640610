import {
  Bullseye,
  EmptyState,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
} from '@patternfly/react-core'
import { SearchIcon } from '@patternfly/react-icons'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table /* data-codemods */,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import './contadores.css'

import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import SitesContext from '../../contexts/SitesContext'
import api, { queryClient } from '../../services/api'
import DownloadButton from '../shared/DownloadButton'
import SelectSite from '../shared/selects/SelectSite'

import PageFrame from '../shared/Page'

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 120,
}

const getData = async (site_id, dateIn, dateFin) => {
  if (!site_id) return { data: [], columns: [] }
  const path = `/sites/${site_id}/availability/counters`
  const params = new URLSearchParams({
    date_in: dateIn.toISOString().split('T')[0],
    date_fin: dateFin.toISOString().split('T')[0],
  })
  const response = await api.get(path, { params })
  return response.data
}

const prefetch = async (site_id, dateIn, dateFin) => {
  await queryClient.prefetchQuery(
    ['contadores', site_id, dateIn, dateFin],
    async () => getData(site_id, dateIn, dateFin),
    queryOptions
  )
}

const ContadoresTable = ({ data, columns }) => {
  const rows = new Set(data.map(d => d.turb_name))
  const rowMap = new Map()
  data.forEach(e => rowMap.set(`${e.turb_name}__${e.column_name}`, e.value))
  return (
    <div className='container-table-aero'>
      <OuterScrollContainer className='pf-v5-u-box-shadow-md'>
        <InnerScrollContainer className='pf-v5-u-box-shadow-md'>
          <Table
            variant='compact'
            aria-label='container-table-aero'
            isStickyHeader
            className='compact-table'
          >
            <Thead>
              <Tr>
                <Th className='pf-m-wrap'>Aerogerador</Th>
                {columns.map(col => (
                  <Th key={col} className='pf-m-wrap'>
                    {col}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {Array.from(rows).map(row => (
                <Tr key={row}>
                  <Td key={row}>{row}</Td>
                  {columns.map(col => (
                    <Td dataLabel={col} key={col}>
                      {rowMap.get(`${row}__${col}`)}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InnerScrollContainer>
      </OuterScrollContainer>
    </div>
  )
}

const Contadores = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const { site, sites, isLoading: siteIsloading } = useContext(SitesContext)
  const [tsFin, setTsFin] = useState(new Date(Date.parse(new Date()) - 1 * 86400000))
  const [tsIn, setTsIn] = useState(new Date(Date.parse(new Date()) - 8 * 86400000))
  const [dataContadores, setDataContadores] = useState([])

  const { data: _dataContadores, isLoading: contIsLoading } = useQuery(
    ['contadores', site?.site_id, tsIn, tsFin],
    () => getData(site?.site_id, tsIn, tsFin),
    { ...queryOptions, enabled: !siteIsloading }
  )

  useEffect(() => {
    if (contIsLoading || siteIsloading) return

    setDataContadores(_dataContadores)
  }, [contIsLoading, siteIsloading, _dataContadores])

  const { data, columns } = dataContadores || { data: [], columns: [] }
  const isLoading = siteIsloading || !dataContadores || dataContadores.length === 0
  const isContentLoading = (siteIsloading || contIsLoading) && dataContadores.length !== 0

  useEffect(() => {
    if (isLoading) return
    sites.filter(s => s.site_id !== site.site_id).forEach(s => prefetch(s.site_id, tsIn, tsFin))
  }, [isLoading])

  let content
  if (!data || data?.length === 0 || !columns || !columns?.length === 0) {
    content = (
      <Bullseye>
        <EmptyState variant={EmptyStateVariant.sm}>
          <EmptyStateHeader
            titleText='Nenhum registro encontrado para o período selecionado.'
            icon={<EmptyStateIcon icon={SearchIcon} />}
            headingLevel='h3'
          />
        </EmptyState>
      </Bullseye>
    )
  } else {
    content = <ContadoresTable data={data} columns={columns} />
  }

  return (
    <PageFrame
      pageName='Contadores'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      isContentLoading={isContentLoading}
      filters={[
        <SelectSite key='SelectListSites' enableUrlParams />,
        <DatetimeRangePicker
          key='DateSelect'
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart, newEnd) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='date'
          maxEnabled={new Date()}
        />,
        <DownloadButton
          key={`dl_btn_${site?.site_name}`}
          label='Exportar'
          url={`/sites/${site?.site_id}/availability/counters/report?${new URLSearchParams({
            ts_in: tsIn.toISOString().split('T')[0],
            ts_fin: tsFin.toISOString().split('T')[0],
          })}`}
          filename={`Counters_${site?.site_name}.xlsx`}
        />,
      ]}
    >
      {content}
    </PageFrame>
  )
}

export default Contadores
