import { Spinner } from '@patternfly/react-core'
import { CSSProperties, useContext } from 'react'
import { MastData, SignalTypes } from '../../../client'
import MastContext from '../../../contexts/MastContext'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import { plot_color_scale_200 } from '../../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../../utils/plotly'
import { sortByDateKey } from '../../../utils/sort'
import Plot from '../../shared/Plot'
import useData from '../useData'

const config = { ...defaultConfig }
const style: CSSProperties = {
  minHeight: '10rem',
  height: calcVerticalHeight({ gridSize: 4, elementSize: 3 }),
  width: '100%',
}

const PlotSignal = ({
  dtIn,
  dtFin,
  signalType,
  revision,
}: {
  dtIn: Date
  dtFin: Date
  signalType: SignalTypes
  revision: number
}) => {
  const { mast, sensors: allSensors } = useContext(MastContext)
  const { mastDataBySensor, dataIsloading } = useData(dtIn, dtFin)
  const sensors = allSensors.filter(s => signalType.id === s.signal_type_id)

  const Y_AXIS_RANGE: Record<string, [number, number]> = {
    BAT: [10, 15],
    TMP: [0, 40],
    BAR: [800, 1000],
  }

  const layout = {
    template: layoutTemplate,
    range: [dtIn, dtFin],
    autorange: false,
    yaxis: { range: Y_AXIS_RANGE[signalType.type] },
  }

  if (dataIsloading) return null

  const data = sensors
    .flatMap((sensor, i) => {
      const _mastData = mastDataBySensor.get(sensor.id)
      if (!_mastData) {
        console.log(`Unable to find data for sensor:${sensor.label}`)
        return null
      }
      const mastData = sortByDateKey<MastData>(_mastData, 'ts', 'asc')
      const x = mastData.map(d => d.ts)
      const avg: number[] = []
      // const max: number[] = []
      // const min: number[] = []
      const sensorColor: string = plot_color_scale_200[i]
      mastData.forEach(md => {
        avg.push(md.avg)
        // max.push(md.max)
        // min.push(md.min)
      })

      return [
        {
          x,
          y: avg,
          type: 'scatter',
          mode: 'line',
          name: `${sensor.label} - AVG`,
          marker: { color: sensorColor },
        },
        // {
        //   x,
        //   y: max,
        //   type: 'scatter',
        //   mode: 'line',
        //   name: `${sensor.label} - MAX`,
        //   marker: { color: sensorColor },
        // },
        // {
        //   x,
        //   y: min,
        //   type: 'scatter',
        //   mode: 'line',
        //   name: `${sensor.label} - MIN`,
        //   marker: { color: sensorColor },
        // },
      ]
    })
    .filter(d => d)

  if (data.length === 0) return <Spinner className='pf-v5-u-m-xl' size='xl' aria-label='Loading' />

  return (
    <div style={style}>
      <Plot
        key={mast.id}
        useResizeHandler
        data={data}
        layout={layout}
        config={config}
        style={style}
        revision={revision}
      />
    </div>
  )
}
export default PlotSignal
