import {
  Divider,
  Grid,
  GridItem,
  Panel,
  PanelMain,
  PanelMainBody,
  Tab,
  Tabs,
  TabTitleText,
} from '@patternfly/react-core'
import { useContext, useEffect, useState } from 'react'
import { Turbines } from '../../client'
import PanelSickHeader from '../../components/PanelStickyHeader'
import Select from '../../components/selects/Select'
import SitesContext from '../../contexts/SitesContext'
import { categories } from './constants'
import PlotDailyTurb from './PlotDailyTurb'
import PlotDailyTurbTotal from './PlotDailyTurbTotal'
import PlotMonthlyTurb from './PlotMonthlyTurb'
import PlotMonthlyTurbTotal from './PlotMonthlyTurbTotal'
import PlotMonthWTG from './PlotMonthWTG'
import PlotMonthWTGTotal from './PlotMonthWTGTotal'

const PanelTurb = ({
  dateIn,
  dateFin,
  revision,
  categorySelection,
}: {
  dateIn: Date
  dateFin: Date
  revision: number
  categorySelection: categories
}) => {
  const { turbs, isLoading: sitesIsLoading } = useContext(SitesContext)
  const [selected, setSelected] = useState(turbs[0])

  const [activeTypeKey, setActiveTypeKey] = useState<string | number>('Total')

  useEffect(() => {
    if (sitesIsLoading || turbs.length === 0) return
    setSelected(turbs[0])
  }, [sitesIsLoading, turbs])

  return (
    <Panel className='pf-v5-u-box-shadow-sm'>
      <PanelSickHeader title='Disponibilidade Temporal Por WTG' />
      <PanelMain>
        <PanelMainBody>
          <Tabs activeKey={activeTypeKey} onSelect={(_, tabIndex) => setActiveTypeKey(tabIndex)}>
            <Tab eventKey='Total' title={<TabTitleText> {'Total'} </TabTitleText>}></Tab>
            <Tab
              eventKey='Por Categoria'
              title={<TabTitleText> {'Por Categoria'} </TabTitleText>}
            />
          </Tabs>

          <Grid hasGutter>
            <GridItem lg={12}>
              {activeTypeKey === 'Total' ? (
                <PlotMonthWTGTotal
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotMonthWTG
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                />
              )}
            </GridItem>
            <GridItem lg={12}>
              <Divider />
            </GridItem>
            <GridItem lg={12}>
              <Select<Turbines>
                items={turbs}
                itemValueName='name'
                itemKeyName='turb_id'
                selected={selected}
                onChange={setSelected}
                isLoading={sitesIsLoading}
                enableFilter
                maxHeight={250}
              />
            </GridItem>
            <GridItem lg={7}>
              {activeTypeKey === 'Total' ? (
                <PlotDailyTurbTotal
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  turbId={selected.turb_id}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotDailyTurb
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  turbId={selected.turb_id}
                  revision={revision}
                />
              )}
            </GridItem>
            <GridItem lg={5}>
              {activeTypeKey === 'Total' ? (
                <PlotMonthlyTurbTotal
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  turbId={selected.turb_id}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotMonthlyTurb
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  turbId={selected.turb_id}
                  revision={revision}
                />
              )}
            </GridItem>
          </Grid>
        </PanelMainBody>
      </PanelMain>
    </Panel>
  )
}
export default PanelTurb
