import { DateTime } from 'luxon'
import months from './months'

export const DATE_FORMAT = 'yyyy/MM/dd'
export const DATETIME_FORMAT = 'yyyy/MM/dd HH:mm'

export const formatDateMonthStr = (date: Date) => {
  const dateSplit = date.toISOString().split('T')[0].split('-')
  const month = months[parseInt(dateSplit[1]) - 1]

  return `${dateSplit[2]} ${month}`
}

export const formatDateWithoutTime = (date: Date) => {
  return date.toISOString().split('T')[0]
}

export const withoutTimeToDate = (date: string) => {
  const [year, month, day] = date.split('-')
  return new Date(Number(year), Number(month) - 1, Number(day))
}

export const formatDateTimeBrl = (date: Date | DateTime) => {
  let dt: DateTime
  if (date instanceof Date) {
    dt = DateTime.fromJSDate(date)
  } else {
    dt = date
  }

  return dt.toFormat(DATETIME_FORMAT)
}

export const toISOString = (date: Date) => {
  date.setMilliseconds(0)
  const dt = DateTime.fromJSDate(date)
  return dt.toISO()?.split('-').slice(0, 3).join('-') ?? ''
}

export const fmtDateStringToBRLDateString = (dtString: string) => {
  return formatDateTimeBrl(new Date(dtString))
}
