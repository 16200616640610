import { useContext, useMemo, useState } from 'react'
import { DowntimeAero, DowntimeBop, DowntimeONS, Turbines } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import ColorScale, { COLOR_SCALES } from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

import { Stack, StackItem } from '@patternfly/react-core'
import { InnerScrollContainer, OuterScrollContainer } from '@patternfly/react-table'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import { formatDateTimeBrl } from '../../utils/formatDate'
import ModalTimeline from './ModalTimeline'

const PLOT_ONS_HEIGHT = 2
const PLOT_BOTTOM_INNER_HEIGHT = 3

const EMPTY_TRACE = { type: 'bar', orientation: 'h', base: [1], x: [1], y: [''] }

const PLOT_LEFT_MARGIN = 100

const makeTrace = ({
  ts_in,
  ts_fin,
  turb_id,
  id,
  d,
  turbsMap,
}: {
  ts_in?: string
  ts_fin?: string
  turb_id?: number
  tipo_id?: number
  id?: string
  d: DowntimeBop | DowntimeAero | null
  turbsMap: Map<number, Turbines>
}) => {
  const turbName = turbsMap.get(turb_id ?? 0)?.name ?? ''
  if (d === null || !ts_in || !ts_fin) return { ...EMPTY_TRACE, y: [turbName] }

  const tsIn = new Date(ts_in)
  const tsFin = new Date(ts_fin)

  return {
    d,
    type: 'bar',
    orientation: 'h',
    base: [tsIn.getTime()],
    x: [new Date(ts_fin).getTime() - tsIn.getTime()],
    y: [turbName],
    marker: {
      color: COLOR_SCALES.categorical[(d?.tipo_id ?? 0) % COLOR_SCALES.categorical.length][0],
      ...((d?.tipo_id ?? 0) % COLOR_SCALES.categorical.length === 9 && {
        line: {
          color: COLOR_SCALES.orange,
          width: 2,
        },
      }),
    },
    hovertemplate:
      `<b>${turbName}</b><br>` +
      `Inicio: ${formatDateTimeBrl(tsIn)}<br>Fim: ${formatDateTimeBrl(tsFin)}` +
      `<br>Id: ${id?.substring(0, 8)}` +
      '<br><extra></extra>',
  }
}

const PlotTimeline = ({
  revision,
  selectedDate,
  downtimeBop,
  downtimeAero,
  downtimeOns,
  dataIsLoading,
}: {
  revision: number
  selectedDate: Date
  downtimeBop: DowntimeBop[]
  downtimeAero: DowntimeAero[]
  downtimeOns: DowntimeONS[]
  dataIsLoading: boolean
}) => {
  const { turbs, turbsMap } = useContext(SitesContext)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dataToUpdate, setDataToUpdate] = useState<DowntimeBop | DowntimeAero | DowntimeONS | null>(
    null
  )

  const minDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
  const maxDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1)

  const traces = useMemo(() => {
    if (dataIsLoading || !downtimeBop) return []

    const downtimeBopFlat = downtimeBop.flatMap(d => d.turbs.map(t => ({ ...t, d })))

    const traces = turbs.map(t => {
      const emptyTrace = [makeTrace({ turb_id: t.turb_id, turbsMap, d: null })]
      const downtimeAeroTurb = downtimeAero.filter(a => a.turb_id === t.turb_id)
      const aeroTraces =
        downtimeAeroTurb.length === 0
          ? emptyTrace
          : downtimeAeroTurb.map(a => makeTrace({ turbsMap, ...a, d: a, id: a.id }))

      const downtimeBopTurb = downtimeBopFlat.filter(a => a.turb_id === t.turb_id)
      const bopTraces =
        downtimeBopTurb.length === 0
          ? emptyTrace
          : downtimeBopTurb.map(a => makeTrace({ turbsMap, ...a, id: a.d.id }))

      return [...aeroTraces, ...bopTraces]
    })
    return traces.flat()
  }, [downtimeBop, downtimeAero, dataIsLoading])

  const onsTraces = useMemo(() => {
    const emptyTrace = { ...EMPTY_TRACE, y: ['Restrições'] }
    if (dataIsLoading || !downtimeOns || downtimeOns.length === 0) return [emptyTrace]

    const traces = downtimeOns.map(d => {
      if (!d.ts_in || !d.ts_fin) return [emptyTrace]

      const tsIn = new Date(d.ts_in)
      const tsFin = new Date(d.ts_fin)

      return {
        d,
        type: 'bar',
        orientation: 'h',
        base: [tsIn.getTime()],
        x: [tsFin.getTime() - tsIn.getTime()],
        y: ['Restrições'],
        marker: {
          color: COLOR_SCALES.orange[0],
        },
        hovertemplate:
          `Inicio: ${formatDateTimeBrl(tsIn)} Fim: ${formatDateTimeBrl(tsFin)}` +
          '<br><extra></extra>',
      }
    })

    return traces
  }, [downtimeOns, dataIsLoading])

  const middleLayout = {
    template: layoutTemplate,
    margin: { t: 10, r: 10, l: PLOT_LEFT_MARGIN, b: 0 },
    barmode: 'stack',
    showlegend: false,
    hovermode: 'closest',
    hoverlabel: { bgcolor: ColorScale.branco, font: { color: ColorScale.preto } },
    xaxis: {
      ...defaultAxis,
      type: 'date',
      range: [minDate, maxDate],
      fixedrange: true,
    },
    yaxis: {
      ...defaultAxis,
      automargin: true,
      type: 'category',
      fixedrange: true,
    },
  }
  const topLayout = {
    ...middleLayout,
    margin: { t: 13, r: 30, l: PLOT_LEFT_MARGIN + 5, b: 2 },
    showgrid: false,
    xaxis: { ...middleLayout.xaxis, showgrid: false, range: [minDate, maxDate] },
    yaxis: { ...middleLayout.yaxis, showgrid: false },
  }
  const bottomLayout = {
    ...middleLayout,
    ...topLayout,
    margin: { t: 0, r: 30, l: PLOT_LEFT_MARGIN + 5, b: 85 },
  }

  const config = { ...defaultConfig }

  const onClick = (e: any) => {
    setDataToUpdate(e.points[0].data.d)
    setIsModalOpen(true)
  }

  return (
    <Stack style={{ backgroundColor: ColorScale.branco_clear }}>
      <StackItem style={{ height: `${PLOT_ONS_HEIGHT}rem` }}>
        <Plot
          divId={'PLOT_DIVS.TIMELINE.TOP'}
          useResizeHandler
          key={revision}
          data={onsTraces}
          layout={topLayout}
          config={config}
          onClick={onClick}
          style={{ height: `${PLOT_ONS_HEIGHT}rem` }}
        />
      </StackItem>
      {/* OuterScrollContainer não deve ser incluído */}
      <InnerScrollContainer>
        <StackItem>
          <Plot
            divId={'PLOT_DIVS.TIMELINE.MIDDLE'}
            useResizeHandler
            key={revision}
            data={traces}
            layout={middleLayout}
            config={config}
            style={{
              height: `${turbs.length * 1.1 + 1}rem`,
            }}
            onClick={onClick}
          />
        </StackItem>
      </InnerScrollContainer>
      <StackItem style={{ height: `${PLOT_BOTTOM_INNER_HEIGHT}rem` }}>
        <Plot
          divId={'PLOT_DIVS.TIMELINE.BOTTOM'}
          useResizeHandler
          key={revision}
          data={[EMPTY_TRACE]}
          layout={bottomLayout}
          config={config}
          style={{ height: `${PLOT_BOTTOM_INNER_HEIGHT}rem` }}
        />
      </StackItem>
      <ModalTimeline
        isOpen={isModalOpen}
        onClose={() => {
          setDataToUpdate(null)
        }}
        dataToUpdate={dataToUpdate}
        selectedDate={selectedDate}
      />
    </Stack>
  )
}

export default PlotTimeline
