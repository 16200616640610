import { Text } from '@patternfly/react-core'

import calcVerticalHeight from '../../utils/calcVerticalHeight'
import COLORS from '../../utils/colorScale'
import months from '../../utils/months'
import Plot from '../shared/Plot'
import plotConfigButtons from './plotConfigButtons'
import plotTemplate from './plotTemplate'

import { AvailabilityMonthly } from '../../client'

interface IProps {
  dataChart: AvailabilityMonthly[]
  yearSelected: number
  isLoading: boolean
  revision: number
}

const ChartAvailableEnergyMonthly = (props: IProps) => {
  const { dataChart, yearSelected, isLoading, revision } = props

  if (isLoading || dataChart.length === 0) return null

  const filteredData = dataChart
    .sort((a: AvailabilityMonthly, b: AvailabilityMonthly) => a.mm - b.mm)
    .filter(el => el.yyyy === yearSelected)

  const minAvailableValue = () => {
    let min = 100
    if (filteredData.length === 0) return 0
    filteredData.forEach(el => {
      if (el.available !== undefined && el.available < min) {
        min = el.available
      }
    })
    return (Math.floor(min / 10) - 1) * 10
  }

  const data = [
    {
      x: months,
      y: filteredData.map(e => e.available),
      type: 'bar',
      marker: { color: COLORS.verde },
      name: 'Disponibilidade',
    },
    {
      x: months,
      y: filteredData.map(e => 100 - (e.bop_parada ?? 100)),
      name: 'BOP parada',
      type: 'bar',
      marker: { color: COLORS.amarelo_pantone },
    },
    {
      x: months,
      y: filteredData.map(e => 100 - (e.bop_restric ?? 100)),
      name: 'BOP restrição ONS',
      type: 'bar',
      marker: { color: COLORS.laranja_international },
    },
    {
      x: months,
      y: filteredData.map(e => 100 - (e.turbine_parada ?? 100)),
      name: 'Turbina parada',
      type: 'bar',
      marker: { color: COLORS.roxo_indigo },
    },
    {
      x: months,
      y: filteredData.map(e => 100 - (e.turbine_derate ?? 100)),
      name: 'Turbina derate',
      type: 'bar',
      marker: { color: COLORS.azul_pantone },
    },
    {
      x: months,
      y: filteredData.map(e => e.available_acc),
      mode: 'lines',
      line: { dash: 'Solid', width: 2 },
      marker: { color: COLORS.cinza_escuro },
      name: 'Disponibilidade Acumulada',
    },
  ]

  const layout = {
    template: plotTemplate,
    barmode: 'stack',
    autosize: true,
    yaxis: { range: [minAvailableValue(), 100] },
    legend: { font: { size: 10.5 }, traceorder: 'normal' },
  }

  const style = {
    width: '100%',
    height: calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '2rem' }),
  }

  return (
    <div>
      <Text component='h2' className='no-bold'>
        {`Disponibilidade energética mensal ${yearSelected}`}
      </Text>
      <Plot
        divId='ChartAvailableEnergyMonthly'
        data={data}
        layout={layout}
        config={plotConfigButtons}
        useResizeHandler
        style={style}
        key={revision + Number(yearSelected)}
      />
    </div>
  )
}

export default ChartAvailableEnergyMonthly
