import COLORS from '../../utils/colorScale'
import months from '../../utils/months'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

import { Production } from '../../client'

interface IProps {
  monthlyData: Production[]
  year: number
  month: number
  revision: number
  plotStyle: any
  isLoading: boolean
}

const filterMonthly = (dailyData: Production[], year: number) => {
  return dailyData
    .filter((d: Production) => d.yyyy === year)
    .sort((a: Production, b: Production) => a.mm - b.mm)
}

const ChartProductionMonthly = (props: IProps) => {
  const { monthlyData, year, month, revision, plotStyle, isLoading } = props

  if (isLoading) return null

  const config = {
    ...defaultConfig,
    displayModeBar: false,
  }
  const layout = {
    template: layoutTemplate,
    margin: { b: 30, t: 5, l: 50, r: 50 },
    yaxis: {
      title: 'Produção mensal [GWh]',
      side: 'left',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      fixedrange: true,
    },
    yaxis2: {
      title: 'Produção acumulada [GWh]',
      side: 'right',
      gridcolor: COLORS.transparent_bg,
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      fixedrange: true,
      overlaying: 'y',
    },
    xaxis: { linewidth: 2, gridwidth: 2, mirror: 'ticks', fixedrange: true },
    paper_bgcolor: COLORS.transparent_bg,
    plot_bgcolor: COLORS.transparent_bg,
  }
  const data = [
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      name: 'Real Mensal',
      type: 'bar',
      marker: { color: COLORS.verde },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      name: 'P50 Mensal',
      type: 'bar',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.azul_escuro },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      yaxis: 'y2',
      name: 'Real Ac.',
      type: 'scatter',
      mode: 'lines+markers',
      line: { width: 5 },
      marker: { color: COLORS.amarelo_pantone },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      name: 'P50 Ac',
      type: 'scatter',
      yaxis: 'y2',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.roxo_indigo },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      yaxis: 'y2',
      name: 'P50 Proj Ac',
      type: 'scatter',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.laranja_amarelo },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      name: 'P90 Proj Ac',
      type: 'scatter',
      yaxis: 'y2',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.laranja_international },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      name: 'PPA Ac',
      yaxis: 'y2',
      type: 'scatter',
      line: { width: 5 },
      marker: { color: COLORS.roxo },
      hoverinfo: 'all',
    },
  ]
  const formatNumber = (value: number | undefined) => {
    if (value) return Math.round(value / 1000)
    return undefined
  }

  const monthly = filterMonthly(monthlyData, year)
  months.forEach((_, i) => {
    data[0].y.push(formatNumber(monthly[i]?.actual_month))
    data[1].y.push(formatNumber(monthly[i]?.p50_month))
    data[2].y.push(formatNumber(monthly[i]?.actual_ac))
    data[3].y.push(formatNumber(monthly[i]?.p50_ac))
    data[4].y.push(i + 2 <= month ? undefined : formatNumber(monthly[i]?.p50_proj_ac))
    data[5].y.push(i + 2 <= month ? undefined : formatNumber(monthly[i]?.p90_proj_ac))
    data[6].y.push(formatNumber(monthly[i]?.ppa_ac))
  })
  const style = { ...plotStyle }

  return (
    <div className='pf-v5-u-p-sm'>
      <Plot
        divId='ChartProductionMonthly'
        useResizeHandler
        data={data}
        layout={layout}
        config={config}
        style={style}
        key={revision}
      />
    </div>
  )
}

export default ChartProductionMonthly
