import { GoogleMap, KmlLayer, useLoadScript } from '@react-google-maps/api'
import { useContext, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { SettingsAPI, SettingsTowerMap, SettingsTurbineMap, TowersAPI } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import { ICriticidadeNivel } from '../../utils/criticidadeNames'
import PageFrame from '../shared/Page'
import ErrorPage from '../shared/Page/ErrorPage'
import SelectSite from '../shared/selects/SelectSite'
import { ButtonsExibição } from './ButtonsExibicao'
import { CriticidadeSelect } from './CriticidadeSelect'
import './mapa.css'
import Ocorrencias from './Ocorrencias'
import Performance from './Performance'
import { ResetZoom } from './ResetZoom'
import ShowSelect from './ShowSelect'
import Torres from './Torres'

export type IMarkerOptions = google.maps.MarkerOptions & {
  isAdded: boolean | undefined
}

export type IExibicao = 'performance' | 'ocorrencias'

export type turbsMapType = {
  [key: number]: SettingsTurbineMap
}

export type towerMapType = {
  [key: number]: SettingsTowerMap
}

const { REACT_APP_APIKEY_GOOGLE_MAPS, REACT_APP_MAP_ID_GOOGLE_MAPS } = process.env

const GOOGLE_MAPS_API = REACT_APP_APIKEY_GOOGLE_MAPS

const queryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 60 * 24,
}

const Mapa = () => {
  const {
    site,
    sites,
    turbs,
    isLoading: isLoadingContext,
    setActiveSite,
  } = useContext(SitesContext)

  const [searchParams] = useSearchParams()
  const { siteName } = useParams()

  const modoExibicao: IExibicao =
    searchParams.get('modo') === 'ocorrencias' ? 'ocorrencias' : 'performance'

  const [isNavOpen, setNavOpen] = useState(false)
  const [exibicao, setExibicao] = useState<IExibicao>(modoExibicao || 'performance')
  const [selectedCriticidade, setSelectedCriticidade] = useState<ICriticidadeNivel>('0')

  const [showTowers, setShowTowers] = useState(true)
  const [showTowersAma, setShowTowersAma] = useState(true)
  const [showKmlLayer, setShowKmlLayer] = useState(true)
  const [showTurbsData, setShowTurbsData] = useState(true)

  const [turbsMap, setTurbsMap] = useState<turbsMapType>({})
  const [towerMap, setTowerMap] = useState<towerMapType>({})

  const { isLoading: isLoadingSettingsTurbs, data: settingsTurbs } = useQuery(
    ['settingsTurbs', site.site_id],
    async () => {
      if (!site) return []

      const settingsTurbs = await SettingsAPI.getSiteTurbines({ siteId: site.site_id })
      return settingsTurbs.sort((a, b) => a.turb_id - b.turb_id)
    },
    {
      ...queryOptions,
      enabled: !!site.site_id,
    }
  )

  useEffect(() => {
    if (!settingsTurbs) return
    const _settingsTurbs = settingsTurbs?.sort((a, b) => a.turb_id - b.turb_id)
    const turbMap: turbsMapType = {}
    if (_settingsTurbs?.length) _settingsTurbs.forEach(t => (turbMap[t.turb_id] = t))
    setTurbsMap(turbMap)
  }, [settingsTurbs])

  const { isLoading: isLoadingSettingsTowers, data: settingsTowers } = useQuery(
    ['settingsTurbs'],
    async () => {
      const settingsTurbs = await SettingsAPI.getTowers()
      return settingsTurbs.sort((a, b) => a.tower_id - b.tower_id)
    },
    queryOptions
  )

  useEffect(() => {
    const _settingsTowers = settingsTowers?.sort((a, b) => a.tower_id - b.tower_id)
    const turbMap: towerMapType = {}
    if (_settingsTowers?.length) _settingsTowers.forEach(t => (turbMap[t.tower_id] = t))
    setTowerMap(turbMap)
  }, [settingsTowers])

  const navigate = useNavigate()

  const { isLoading: towersIsLoading, data: towers } = useQuery(
    ['towers'],
    () => TowersAPI.getAll().then(r => r.sort((a, b) => a.tower_id - b.tower_id)),
    {
      refetchOnWindowFocus: false,
      retry: true,
      staleTime: 1000 * 60 * 60 * 24,
    }
  )

  const isLoading = isLoadingContext && isLoadingSettingsTurbs && isLoadingSettingsTowers

  useEffect(() => {
    if (isLoadingContext || !siteName) return
    const newSite = sites.find(s => s.site.toLowerCase() === siteName.toLowerCase())
    if (newSite) setActiveSite(newSite.site_id)
  }, [isLoadingContext, sites])

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: String(GOOGLE_MAPS_API),
  })
  if (loadError) return <ErrorPage message='Erro ao carregar mapa' />

  const telao = sessionStorage.getItem('telao')
  const header =
    telao === 'on'
      ? '(var(--a7-filter-bar--height))'
      : '(var(--a7-header--height) + var(--a7-filter-bar--height))'
  const mapHeight = `calc((100vh - ${header})`

  const handleSetExibicao = (_exibicao: IExibicao) => {
    if (!site || !site.site || isLoading || Object.keys(site).length === 0) return
    navigate(`/mapa/${site?.site?.toLowerCase()}?modo=${_exibicao.toLowerCase()}`)
    setExibicao(_exibicao)
  }

  const toggleShowTowers = (showTowers: boolean) => {
    setShowTowers(showTowers)
  }

  const toggleShowTowersAma = (showTowersAma: boolean) => {
    setShowTowersAma(showTowersAma)
  }

  const toggleShowKmlLayer = (showKmlLayer: boolean) => {
    setShowKmlLayer(showKmlLayer)
  }

  const toggleShowTurbsData = (showTurbsData: boolean) => {
    setShowTurbsData(showTurbsData)
  }

  return (
    <PageFrame
      pageName='Mapa'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading || !isLoaded}
      filters={[
        <CriticidadeSelect
          key='selectCriticidade'
          exibicao={exibicao}
          selectedCriticidade={selectedCriticidade}
          setSelectedCriticidade={setSelectedCriticidade}
        />,
        <SelectSite key='selectSites' />,
        <ShowSelect
          key='showSelect'
          exibicao={exibicao}
          toggleShowTowers={toggleShowTowers}
          toggleShowTowersAma={toggleShowTowersAma}
          toggleShowKmlLayer={toggleShowKmlLayer}
          toggleShowTurbsData={toggleShowTurbsData}
        />,
        <ButtonsExibição
          key='buttonsExibicao'
          exibicao={exibicao}
          setExibicao={handleSetExibicao}
        />,
      ]}
    >
      <GoogleMap
        options={{
          isFractionalZoomEnabled: true,
          rotateControl: true,
          scaleControl: true,
          mapId: REACT_APP_MAP_ID_GOOGLE_MAPS,
        }}
        center={{ lat: Number(site.lat ?? 0), lng: Number(site.lon ?? 0) }}
        zoom={Number(site.map_zoom_level) + 1}
        mapTypeId='terrain'
        mapContainerStyle={{
          width: '100%',
          height: mapHeight,
          position: 'absolute',
          top: `calc(${header})`,
          left: 0,
          zIndex: 10,
        }}
      >
        <ResetZoom key='reset_zoom' site={site} />

        {showKmlLayer && (
          <KmlLayer
            url={`https://kmz.rioenergyllc.com/LAYOUT_CONTORNO_${site?.site}.kmz`}
            options={{ clickable: false, preserveViewport: true }}
          />
        )}

        <Performance
          turbsMap={turbsMap}
          turbs={turbs}
          site={site}
          exibicao={exibicao}
          showTurbsData={showTurbsData}
        />
        <Ocorrencias
          id='Ocorencias'
          site={site}
          selectedCriticidade={selectedCriticidade}
          exibicao={exibicao}
        />

        <Torres
          towerMap={towerMap}
          towersIsLoading={towersIsLoading}
          towers={towers}
          showTowers={showTowers}
          showTowersAma={showTowersAma}
          site_id={site.site_id}
        />
      </GoogleMap>
    </PageFrame>
  )
}

export default Mapa
