import { Text } from '@patternfly/react-core'

import calcVerticalHeight from '../../utils/calcVerticalHeight'
import COLORS from '../../utils/colorScale'
import Plot from '../shared/Plot'
import plotConfigButtons from './plotConfigButtons'
import plotTemplate from './plotTemplate'

import { AvailabilityParkMonthly, WindFarms } from '../../client'

interface IProps {
  dataChart: AvailabilityParkMonthly[]
  windFarms: WindFarms[]
  yearSelected: number
  monthSelected: number
  isLoading: boolean
  revision: number
}

const ChartAvailableEnergyParks = (props: IProps) => {
  const { dataChart, windFarms, yearSelected, monthSelected, isLoading, revision } = props

  if (isLoading || dataChart.length === 0) return null

  const farms = windFarms.sort((a, b) => a.wf_id - b.wf_id).map(el => el.wf)

  const filteredData = dataChart
    .filter(el => el.yyyy === yearSelected && el.mm === monthSelected)
    .sort((a, b) => a.wf_id - b.wf_id)

  const minAvailableValue = () => {
    let min = 100
    if (filteredData.length === 0) return 0
    filteredData.forEach(el => {
      if (el.available !== undefined && el.available < min) {
        min = el.available
      }
    })
    return (Math.floor(min / 10) - 1) * 10
  }

  const data = [
    {
      x: farms,
      y: filteredData.map(e => e.available),
      name: 'Disponível',
      type: 'bar',
      width: 0.5,
      marker: { color: COLORS.verde },
    },
    {
      x: farms,
      y: filteredData.map(e => e.bop_parada),
      name: 'BOP parada',
      type: 'bar',
      width: 0.5,
      marker: { color: COLORS.amarelo_pantone },
    },
    {
      x: farms,
      y: filteredData.map(e => e.bop_restric),
      name: 'BOP restrição ONS',
      type: 'bar',
      width: 0.5,
      marker: { color: COLORS.laranja_international },
    },
    {
      x: farms,
      y: filteredData.map(e => e.turbine_parada),
      name: 'Turbina parada',
      type: 'bar',
      width: 0.5,
      marker: { color: COLORS.roxo_indigo },
    },
    {
      x: farms,
      y: filteredData.map(e => e.turbine_derate),
      name: 'Turbina derate',
      type: 'bar',
      width: 0.5,
      marker: { color: COLORS.azul_pantone },
    },
  ]
  const layout = {
    template: plotTemplate,
    autosize: true,
    yaxis: {
      range: [minAvailableValue(), 100],
    },
    barmode: 'stack',
    legend: {
      traceorder: 'normal',
      font: { size: 10.5 },
    },
  }

  const style = {
    width: '100%',
    height: calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: '6.5rem' }),
  }

  return (
    <div className='pf-v5-u-mt-md'>
      <Text component='h2' className='no-bold'>
        Disponibilidade energética dos parques
      </Text>
      <div>
        <Plot
          divId='ChartAvailableEnergyParks'
          data={data}
          layout={layout}
          config={plotConfigButtons}
          useResizeHandler
          style={style}
          key={revision}
        />
      </div>
    </div>
  )
}

export default ChartAvailableEnergyParks
