import {
  Divider,
  Panel,
  PanelHeader,
  PanelMain,
  Progress,
  ProgressMeasureLocation,
  Skeleton,
} from '@patternfly/react-core'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table /* data-codemods */,
  TableText,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'

import { useQuery } from '@tanstack/react-query'
import { range } from 'd3-array'
import { useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { TurbineHealthAPI, TurbinesHealth } from '../../../client'
import SitesContext from '../../../contexts/SitesContext'

import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import ColorScale from '../../../utils/colorScale'

import { farol } from './commons'

const getSortableRowValues = (turbData: TurbinesHealth) => {
  const {
    turb_id,
    health,
    main_shaft,
    pitch,
    hydraulic,
    transformer,
    environment,
    gearbox,
    yaw,
    converter,
    electric,
    generator,
  } = turbData

  return [
    turb_id,
    health,
    main_shaft,
    pitch,
    hydraulic,
    transformer,
    environment,
    gearbox,
    yaw,
    converter,
    electric,
    generator,
  ]
}

const columns = [
  'WTG',
  'Health',
  'Main Shaft',
  'Pitch',
  'Hydraulic',
  'Transformer',
  'Environment',
  'Gearbox',
  'Yaw',
  'Converter',
  'Electric',
  'Generator',
]

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 60 * 24,
}

const fetchData = async (siteId?: number) => {
  if (!siteId) return
  return TurbineHealthAPI.getTurbineHealth({ siteId })
}

export type direction = 'asc' | 'desc' | null

const HealthTable = ({ searchValue }: { searchValue: string }) => {
  const [activeSortDirection, setActiveSortDirection] = useState<direction>(null)
  const [activeSortIndex, setActiveSortIndex] = useState<number | null>(null)

  const { site, turbsMap } = useContext(SitesContext)
  const { isLoading, data: health } = useQuery(
    ['turb-health', site?.site_id],
    async () => fetchData(site?.site_id),
    {
      ...defaultQueryOptions,
      enabled: !!site?.site_id,
    }
  )
  const showSkeleton = isLoading || !health
  const skeleton = <Skeleton />

  const getSortParams = (columnIndex: number) => ({
    sortBy:
      typeof activeSortIndex === 'number'
        ? {
            index: activeSortIndex,
            direction: activeSortDirection || 'asc',
          }
        : {},
    onSort: (_event: React.MouseEvent, index: number, direction: direction) => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  const healthSearch = (turbData: TurbinesHealth) => {
    const turbName = turbsMap.get(turbData.turb_id)?.name
    return turbName?.toLowerCase().includes(searchValue.toLowerCase())
  }

  const sortedHealthData = useMemo(() => {
    if (activeSortIndex !== null) {
      return health?.filter(healthSearch)?.sort((a, b) => {
        const aValue = getSortableRowValues(a)[activeSortIndex]
        const bValue = getSortableRowValues(b)[activeSortIndex]

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          if (activeSortDirection === 'asc') return aValue - bValue

          return bValue - aValue
        }
        if (activeSortDirection === 'asc') {
          return String(aValue)?.localeCompare(String(bValue))
        }

        return String(bValue)?.localeCompare(String(aValue))
      })
    } else {
      return health?.filter(healthSearch).sort((a, b) => a?.turb_id - b?.turb_id)
    }
  }, [health, activeSortDirection, activeSortIndex, searchValue])

  const verticalH = { gridSize: 1, elementSize: 1, customOffset: '3.2rem' }
  const panelStyle = {
    height: calcVerticalHeight({ ...verticalH, customOffset: '0rem' }),
    minHeight: '15rem',
    background: ColorScale.branco,
  }
  const wrapperStyle = {
    height: calcVerticalHeight(verticalH),
  }

  return (
    <Panel style={panelStyle} className='pf-v5-u-box-shadow-md'>
      <PanelHeader>
        <b>Turbine Health</b>
      </PanelHeader>
      <Divider />
      <PanelMain>
        <div style={wrapperStyle}>
          <OuterScrollContainer>
            <InnerScrollContainer>
              <Table
                className='table-turbine-health'
                ouiaId='turb-health-table'
                aria-label='TowerTable'
                isStickyHeader
                variant='compact'
                borders={false}
                gridBreakPoint={'grid-lg'}
              >
                <Thead>
                  <Tr>
                    {columns.map((c, i) => (
                      <Th
                        sort={getSortParams(i)}
                        key={c + '_key'}
                        textCenter
                        className='pf-m-wrap'
                        style={{ width: '6%' }}
                      >
                        {c}
                      </Th>
                    ))}
                  </Tr>
                </Thead>

                <Tbody>
                  {showSkeleton
                    ? range(site.number_of_turbs).map(i => (
                        <Tr key={i}>
                          {columns.map(c => (
                            <Td textCenter key={c + '_key'}>
                              {skeleton}
                            </Td>
                          ))}
                        </Tr>
                      ))
                    : sortedHealthData?.map((th, i) => (
                        <Tr key={i}>
                          <Td textCenter modifier='nowrap' dataLabel='Wind Turbine Generator'>
                            <TableText className={i === 0 ? 'pf-v5-u-mt-sm' : ''}>
                              {turbsMap.get(th.turb_id)?.name}{' '}
                            </TableText>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Health'>
                            <Progress
                              id={`health_turb_${th.turb_id}`}
                              aria-label={`health_turb_${th.turb_id}`}
                              value={th.health}
                              measureLocation={
                                th?.health && th.health > 0
                                  ? ProgressMeasureLocation.inside
                                  : ProgressMeasureLocation.none
                              }
                            />
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Main Shaft'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=MAIN+SHAFT`}
                            >
                              {farol.get(`${th.main_shaft}`)}
                            </Link>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Pitch'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=PITCH`}
                            >
                              {farol.get(`${th.pitch}`)}
                            </Link>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Hydraulic'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=HYDRAULIC`}
                            >
                              {farol.get(`${th.hydraulic}`)}
                            </Link>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Transformer'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=TRANSFORMER`}
                            >
                              {farol.get(`${th.transformer}`)}
                            </Link>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Environment'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=ENVIRONMENT`}
                            >
                              {farol.get(`${th.environment}`)}
                            </Link>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Gearbox'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=GEARBOX`}
                            >
                              {farol.get(`${th.gearbox}`)}
                            </Link>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Yaw'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=YAW`}
                            >
                              {farol.get(`${th.yaw}`)}
                            </Link>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Converter'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=CONVERTER`}
                            >
                              {farol.get(`${th.converter}`)}
                            </Link>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Electric'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=ELECTRIC`}
                            >
                              {farol.get(`${th.electric}`)}
                            </Link>
                          </Td>
                          <Td textCenter modifier='nowrap' dataLabel='Generator'>
                            <Link
                              target={'_blank'}
                              to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                th.turb_id
                              }?system=GENERATOR`}
                            >
                              {farol.get(`${th.generator}`)}
                            </Link>
                          </Td>
                        </Tr>
                      ))}
                </Tbody>
              </Table>
            </InnerScrollContainer>
          </OuterScrollContainer>
        </div>
      </PanelMain>
    </Panel>
  )
}

export default HealthTable
