import { ReactNode } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Alarmes from './features/alarmes'
import ComponentTest from './features/component-test'
import Contadores from './features/contadores'
import CurvaPotencia from './features/curva-potencia'
import CurvaPotenciaBop from './features/curva-potencia-bop'
import EnergyAvailability from './features/disponibilidade-energetica'
import EnergyBridge from './features/energy-bridge'
import ExpectedEnergy from './features/expected-energy'
import ExpectedEnergyTurb from './features/expected-energy-turb'
import HelpPlotly from './features/help-plotly'
import Home from './features/home'
import HomeAlternativa from './features/home-alternativa'
import Login from './features/login'
import Mapa from './features/mapa'
import NotFound from './features/not-found'
import Novidades from './features/novidades'
import OcorrenciasPage from './features/ocorrencias'
import Manutencao from './features/pag-manutencao'
import ParadasRestricoes from './features/paradas-restricoes'
import Producao from './features/producao'
import TorresAnemometricas from './features/torres-anemometricas'
import TowersTimeseries from './features/towers-timeserires'
import TurbHealth from './features/turb-health'
import HeatmapTurbineHealth from './features/turb-health/HeatmapTurbineHealth'
import UsuariosGrupos from './features/usuarios-grupos'
import Windness from './features/windness'

import { isAuthenticated } from './services/auth'

import { MastProvider } from './contexts/MastContext'
import { PermissionProvider } from './contexts/PermissionContext'
import { SitesProvider } from './contexts/SitesContext'
import CalendarComponentTest from './features/calendar-component-test'
import TimeAvailabilityMonth from './features/disponibilidade-temporal/TimeAvailabilityMonth'
import TimeAvailabilityPeriod from './features/disponibilidade-temporal/TimeAvailabilityPeriod'
import MastCalibration from './features/masts/Calibration'
import MastDataViz from './features/masts/DataViz'
import MastEventFlagging from './features/masts/EventFlagging'
import MastRawMappings from './features/masts/RawMapping'
import MastSettings from './features/masts/Settings'
import SettingsTurbs from './features/settings-turbs'

const ProtectedRoute = ({
  redirectPath = '/login',
  children,
}: {
  redirectPath?: string
  children?: ReactNode
}) => {
  if (!isAuthenticated()) {
    return <Navigate to={redirectPath} replace />
  }

  const content = children || <Outlet />

  return (
    <SitesProvider>
      <PermissionProvider>
        <MastProvider>{content}</MastProvider>
      </PermissionProvider>
    </SitesProvider>
  )
}

const AppRouter = () => {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Home />} />
        <Route path='home' element={<Home />} />
        <Route path='home-alternativa' element={<HomeAlternativa />} />
        <Route path='/alarmes' element={<Alarmes />} />
        <Route path='/alarmes/:siteName' element={<Alarmes />} />
        <Route path='/producao' element={<Producao />} />
        <Route path='/producao/:siteName' element={<Producao />} />
        <Route path='/windness' element={<Windness />} />
        <Route path='/windness/:siteName' element={<Windness />} />
        <Route path='/contadores' element={<Contadores />} />
        <Route path='/contadores/:siteName' element={<Contadores />} />
        <Route path='/paginas-manutencao' element={<Manutencao />} />
        <Route path='/usuarios-grupos' element={<UsuariosGrupos />} />
        <Route path='/curva-potencia' element={<CurvaPotencia />} />
        <Route path='/curva-potencia/:siteName' element={<CurvaPotencia />} />
        <Route path='/paradas-restricoes' element={<ParadasRestricoes />} />
        <Route path='/paradas-restricoes/:siteName' element={<ParadasRestricoes />} />
        <Route path='/curva-pot-bop' element={<CurvaPotenciaBop />} />
        <Route path='/curva-pot-bop/:siteName' element={<CurvaPotenciaBop />} />
        <Route path='/torres-anemometricas' element={<TorresAnemometricas />} />
        <Route path='/torres-anemometricas/apresentacao' element={<TorresAnemometricas />} />
        <Route path='/torres-anemometricas/:towerId' element={<TowersTimeseries />} />
        <Route path='/novidades' element={<Novidades />} />
        <Route path='/disponibilidade-energetica' element={<EnergyAvailability />} />
        <Route path='/disponibilidade-energetica/:siteName' element={<EnergyAvailability />} />
        <Route path='/disponibilidade-temporal' element={<TimeAvailabilityMonth />} />
        <Route path='/disponibilidade-temporal/:siteName' element={<TimeAvailabilityMonth />} />
        <Route path='/disponibilidade-temporal/period' element={<TimeAvailabilityPeriod />} />
        <Route
          path='/disponibilidade-temporal/period/:siteName'
          element={<TimeAvailabilityPeriod />}
        />
        <Route path='/mapa' element={<Mapa />} />
        <Route path='/mapa/:siteName' element={<Mapa />} />
        <Route path='/ocorrencias' element={<OcorrenciasPage />} />
        <Route path='/energy-bridge' element={<EnergyBridge />} />
        <Route path='/energy-bridge/:siteName' element={<EnergyBridge />} />
        <Route path='/turbine-health' element={<TurbHealth />} />
        <Route path='/turbine-health/:siteName' element={<TurbHealth />} />
        <Route
          path='/turbine-health/heatmap/:siteName/:turbId'
          element={<HeatmapTurbineHealth />}
        />
        <Route path='/help-plotly' element={<HelpPlotly />} />
        <Route path='/component-test' element={<ComponentTest />} />
        <Route path='/calendar-component-test' element={<CalendarComponentTest />} />
        <Route path='/component-test/:siteName' element={<ComponentTest />} />
        <Route path='/configuracoes-turbs' element={<SettingsTurbs />} />
        <Route path='/configuracoes-turbs/:siteName' element={<SettingsTurbs />} />
        <Route path='/expected-energy' element={<ExpectedEnergy />} />
        <Route path='/expected-energy/:siteName' element={<ExpectedEnergy />} />
        <Route path='/expected-turb-energy' element={<ExpectedEnergyTurb />} />
        <Route path='/expected-turb-energy/:siteName' element={<ExpectedEnergyTurb />} />
        <Route path='/mast/raw-mappings' element={<MastRawMappings />} />
        <Route path='/mast/raw-mappings/:mastId' element={<MastRawMappings />} />
        <Route path='/mast/settings' element={<MastSettings />} />
        <Route path='/mast/event-flagging/' element={<MastEventFlagging />} />
        <Route path='/mast/event-flagging/:mastId' element={<MastEventFlagging />} />
        <Route path='/mast/calibration/' element={<MastCalibration />} />
        <Route path='/mast/calibration/:mastId' element={<MastCalibration />} />
        <Route path='/mast/data-viz' element={<MastDataViz />} />
        <Route path='/mast/data-viz/:mastId' element={<MastDataViz />} />
      </Route>
    </Routes>
  )
}

export default AppRouter
