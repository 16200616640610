import { Stack, StackItem } from '@patternfly/react-core'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import DatetimeMonthPicker from '../../components/calendar/DatetimeMonthPicker'
import SitesContext from '../../contexts/SitesContext'
import { getFilterParams, replaceFilterParams } from '../../utils/url'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import useData from './useData'

import { categories, CATEGORIES_DEFAULTS } from './constants'
import PanelSite from './PanelSite'
import PanelTurb from './PanelTurb'
import PanelWindFarm from './PanelWindFarm'
import SelectCategories from './SelectCategories'

const getDefaultDate = (dateParam: number[] | null) => {
  const dtNow = new Date()

  if (!dateParam || dateParam.length === 0) {
    return new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDate())
  }

  return new Date(dateParam[0])
}

const TimeAvailabilityMonth = () => {
  const { site, isLoading: sitesIsLoading } = useContext(SitesContext)

  const [revision, setRevision] = useState(1)

  const [categorySelection, setCategorySelection] = useState<categories>(CATEGORIES_DEFAULTS)

  const date = new Date()
  const dateParam = getFilterParams('date')
  const [selectedDate, setSelectedDate] = useState(getDefaultDate(dateParam))

  useEffect(() => {
    const dt = getDefaultDate(dateParam)
    setSelectedDate(dt)
  }, [])

  const [dateIn, dateFin] = useMemo(() => {
    const dateIn = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
    const dateFin = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)

    return [dateIn, dateFin]
  }, [selectedDate])

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const dt = getDefaultDate(dateParam)
    setSelectedDate(dt)
  }, [])

  const minDate = new Date(site.commissioning_date)

  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const { isLoading: isContentLoading } = useData(dateIn, dateFin)

  useEffect(() => {
    setRevision(revision + 1)
  }, [isNavOpen, site, sitesIsLoading])

  return (
    <PageFrame
      pageName='Disponibilidade Temporal'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={sitesIsLoading}
      isContentLoading={isContentLoading}
      filters={[
        <SelectCategories
          categorySelection={categorySelection}
          setCategorySelection={setCategorySelection}
          key='SelectCategories'
        />,
        <SelectSite key='SelectSite' enableUrlParams />,
        <DatetimeMonthPicker
          key='DatetimeMonthPicker'
          date={selectedDate}
          maxEnabled={date}
          minEnabled={minDate}
          onChange={e => {
            setSelectedDate(e)
            const newLocation = replaceFilterParams(location, [e], 'date')
            navigate(newLocation)
          }}
        />,
      ]}
    >
      <Stack hasGutter>
        <StackItem>
          <PanelSite
            isLoading={sitesIsLoading}
            dateIn={dateIn}
            dateFin={dateFin}
            revision={revision}
            categorySelection={categorySelection}
          />
        </StackItem>
        <StackItem>
          <PanelWindFarm
            dateIn={dateIn}
            dateFin={dateFin}
            revision={revision}
            categorySelection={categorySelection}
          />
        </StackItem>
        <StackItem>
          <PanelTurb
            dateIn={dateIn}
            dateFin={dateFin}
            revision={revision}
            categorySelection={categorySelection}
          />
        </StackItem>
      </Stack>
    </PageFrame>
  )
}

export default TimeAvailabilityMonth
