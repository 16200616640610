import {
  Button,
  Panel,
  PanelFooter,
  PanelHeader,
  PanelMain,
  PanelMainBody,
} from '@patternfly/react-core'

import { useQuery } from '@tanstack/react-query'
import { CSSProperties, useContext, useState } from 'react'

import { queryClient } from '../../../services/api'

import { MastsAPI } from '../../../client'
import { MastRawMapping } from '../../../client/models/MastRawMapping'
import { ActionState } from '../../../components/actionState'
import EntityTable from '../../../components/EntityTable'
import ModalConfirmationWarning from '../../../components/ModalConfirmationWarning'
import MastContext from '../../../contexts/MastContext'
import useMobile from '../../../hooks/useMobile'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import { fmtDateStringToBRLDateString } from '../../../utils/formatDate'
import PageFrame from '../../shared/Page'
import { KEY_RAW_MAPPINGS } from '../constants'
import SelectMasts from '../SelectMasts'
import ModalRawMapping from './ModalRawMapping'

const style: CSSProperties = {
  height: calcVerticalHeight({
    gridSize: 1,
    elementSize: 1,
    customOffset: '7rem',
  }),
  minHeight: '12rem',
}

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 120,
}

const MastRawMappings = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const { mast, isLoading: mastIsLoading } = useContext(MastContext)
  const isMobile = useMobile()

  const { data: rawMappings, isLoading: rawMappingsIsLoading } = useQuery(
    [KEY_RAW_MAPPINGS, mast.id],
    () => MastsAPI.getRawMapping({ mastId: mast.id }),
    { ...queryOptions, enabled: mast.id !== 0 }
  )

  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalUpdateOpen, setModalUpdateOpen] = useState(false)
  const [dataToUpdate, setDataToUpdate] = useState<MastRawMapping>()

  const handleClickCreateModal = () => {
    setModalOpen(true)
  }
  const handleClickUpdateModal = (rawMapping: MastRawMapping) => {
    setModalUpdateOpen(true)
    setDataToUpdate(rawMapping)
  }

  const [isModalWarningOpen, setModalWarningOpen] = useState(false)

  const [actionState, setActionState] = useState<ActionState>('idle')
  const [dataToDelete, setDataToDelete] = useState<string>('')

  const handleModalConfirmationWarningToggle = () => {
    setActionState('idle')
    setModalWarningOpen(!isModalWarningOpen)
  }

  const handleDeleteClick = (rawMapping: MastRawMapping) => {
    if (!rawMapping.id) return
    setDataToDelete(rawMapping.id)
    handleModalConfirmationWarningToggle()
  }

  const deleteRawMapping = async (rawMappingId: string) => {
    setActionState('pending')
    try {
      await MastsAPI.deleteRawMapping({ id: rawMappingId, mastId: mast.id })
    } catch (error) {
      setActionState('failure')
      return
    }
    setActionState('success')
    setModalOpen(false)
    await queryClient.invalidateQueries([KEY_RAW_MAPPINGS, mast.id])
  }

  return (
    <PageFrame
      pageName='RawMappings'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={mastIsLoading}
      isContentLoading={rawMappingsIsLoading}
      filters={[<SelectMasts key='SelectMasts' />]}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelHeader>
          <b>Relação de Mapeamentos de Coluna</b>
        </PanelHeader>
        <PanelMain>
          <PanelMainBody>
            <EntityTable<MastRawMapping>
              items={rawMappings ?? []}
              itemKeyName='id'
              onClickDelete={handleDeleteClick}
              onClickEdit={handleClickUpdateModal}
              style={style}
              isCompact
              columnConfig={[
                { key: 'id', description: 'Id', formatter: v => String(v).split('-')[0] },
                { key: 'ts_in', description: 'Inicio', formatter: fmtDateStringToBRLDateString },
                { key: 'ts_fin', description: 'Fim', formatter: fmtDateStringToBRLDateString },
                { key: 'column_name', description: 'Coluna' },
                { key: 'metric', description: 'Métrica' },
                { key: 'username', description: 'Usuário' },
              ]}
            />
          </PanelMainBody>
          <PanelFooter>
            <Button
              onClick={handleClickCreateModal}
              style={{
                height: '36px',
                margin: 0,
                ...(isMobile && { width: '100%' }),
              }}
            >
              Adicionar
            </Button>
          </PanelFooter>
        </PanelMain>
      </Panel>
      <ModalRawMapping
        isOpen={isModalOpen}
        onClose={() => setModalOpen(!isModalOpen)}
        modalType='create'
      />
      <ModalRawMapping
        isOpen={isModalUpdateOpen}
        onClose={() => setModalUpdateOpen(!isModalUpdateOpen)}
        modalType='update'
        rawMapping={dataToUpdate}
      />
      <ModalConfirmationWarning<string>
        isOpen={isModalWarningOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={id => deleteRawMapping(id)}
        title='Remover Raw Mapping'
        element={dataToDelete}
        actionState={actionState}
        alertMessage='Tem certeza que deseja remover este registro? Essa ação não poderá ser desfeita.'
      />
    </PageFrame>
  )
}

export default MastRawMappings
