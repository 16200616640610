import { Grid, GridItem, Text } from '@patternfly/react-core'

import maintenance from '../../../assets/img/maintenance.svg'

interface IProps {
  message?: string
}

const Maintenance = (props: IProps) => {
  const { message } = props
  const messageText = message || 'Em manutenção'

  return (
    <Grid>
      <GridItem sm={1} md={3} lg={4} />
      <GridItem sm={10} md={6} lg={4}>
        <img src={maintenance} alt='página em manutenção' id='maintenance-img' />
        <Text>{messageText}</Text>
      </GridItem>
      <GridItem sm={1} md={3} lg={4} />
    </Grid>
  )
}

export default Maintenance
