/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalibrationEvents } from '../models/CalibrationEvents';
import type { FlagEvents } from '../models/FlagEvents';
import type { FlagTypes } from '../models/FlagTypes';
import type { MastData } from '../models/MastData';
import type { MastRawMapping } from '../models/MastRawMapping';
import type { Masts } from '../models/Masts';
import type { Sensors } from '../models/Sensors';
import type { SignalTypes } from '../models/SignalTypes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MastsAPI {

  /**
   * Get All
   * @returns Masts Successful Response
   * @throws ApiError
   */
  public static getAll(): CancelablePromise<Array<Masts>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/masts',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Sensors
   * @returns Sensors Successful Response
   * @throws ApiError
   */
  public static getSensors({
    mastId,
  }: {
    mastId: number,
  }): CancelablePromise<Array<Sensors>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/masts/{mast_id}/sensors',
      path: {
        'mast_id': mastId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Flag Events
   * @returns FlagEvents Successful Response
   * @throws ApiError
   */
  public static getFlagEvents({
    mastId,
  }: {
    mastId: number,
  }): CancelablePromise<Array<FlagEvents>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/masts/{mast_id}/flag-events',
      path: {
        'mast_id': mastId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Flag Types
   * @returns FlagTypes Successful Response
   * @throws ApiError
   */
  public static getFlagTypes(): CancelablePromise<Array<FlagTypes>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/masts/flag-types',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Signal Types
   * @returns SignalTypes Successful Response
   * @throws ApiError
   */
  public static getSignalTypes(): CancelablePromise<Array<SignalTypes>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/masts/signal-types',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Mast
   * @returns Masts Successful Response
   * @throws ApiError
   */
  public static getMast({
    mastId,
  }: {
    mastId: number,
  }): CancelablePromise<Masts> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/masts/{mast_id}',
      path: {
        'mast_id': mastId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Mast
   * @returns Masts Successful Response
   * @throws ApiError
   */
  public static updateMast({
    mastId,
    requestBody,
  }: {
    mastId: number,
    requestBody: Masts,
  }): CancelablePromise<Masts> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/masts/{mast_id}',
      path: {
        'mast_id': mastId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Mast Data
   * @returns MastData Successful Response
   * @throws ApiError
   */
  public static getMastData({
    mastId,
    dateIn,
    dateFin,
    deltaMonths = 6,
  }: {
    mastId: number,
    dateIn?: (string | null),
    dateFin?: (string | null),
    deltaMonths?: number,
  }): CancelablePromise<Array<MastData>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/masts/{mast_id}/mast-data',
      path: {
        'mast_id': mastId,
      },
      query: {
        'date_in': dateIn,
        'date_fin': dateFin,
        'delta_months': deltaMonths,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Raw Mapping
   * @returns MastRawMapping Successful Response
   * @throws ApiError
   */
  public static getRawMapping({
    mastId,
  }: {
    mastId: number,
  }): CancelablePromise<Array<MastRawMapping>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/masts/{mast_id}/raw-mapping',
      path: {
        'mast_id': mastId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Raw Mapping
   * @returns MastRawMapping Successful Response
   * @throws ApiError
   */
  public static createRawMapping({
    mastId,
    requestBody,
  }: {
    mastId: number,
    requestBody: MastRawMapping,
  }): CancelablePromise<MastRawMapping> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/masts/{mast_id}/raw-mapping',
      path: {
        'mast_id': mastId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Raw Mapping
   * @returns MastRawMapping Successful Response
   * @throws ApiError
   */
  public static updateRawMapping({
    mastId,
    requestBody,
  }: {
    mastId: number,
    requestBody: MastRawMapping,
  }): CancelablePromise<MastRawMapping> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/masts/{mast_id}/raw-mapping',
      path: {
        'mast_id': mastId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Raw Mapping
   * @returns string Successful Response
   * @throws ApiError
   */
  public static deleteRawMapping({
    mastId,
    id,
  }: {
    mastId: number,
    id: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/masts/{mast_id}/raw-mapping/{id}',
      path: {
        'mast_id': mastId,
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Calibration
   * @returns CalibrationEvents Successful Response
   * @throws ApiError
   */
  public static getCalibration({
    mastId,
  }: {
    mastId: number,
  }): CancelablePromise<Array<CalibrationEvents>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/masts/{mast_id}/calibration',
      path: {
        'mast_id': mastId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Calibration
   * @returns CalibrationEvents Successful Response
   * @throws ApiError
   */
  public static createCalibration({
    mastId,
    requestBody,
  }: {
    mastId: number,
    requestBody: CalibrationEvents,
  }): CancelablePromise<CalibrationEvents> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/masts/{mast_id}/calibration',
      path: {
        'mast_id': mastId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Calibration
   * @returns CalibrationEvents Successful Response
   * @throws ApiError
   */
  public static updateCalibration({
    mastId,
    requestBody,
  }: {
    mastId: number,
    requestBody: CalibrationEvents,
  }): CancelablePromise<CalibrationEvents> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/masts/{mast_id}/calibration',
      path: {
        'mast_id': mastId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Calibration
   * @returns string Successful Response
   * @throws ApiError
   */
  public static deleteCalibration({
    mastId,
    id,
  }: {
    mastId: number,
    id: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/masts/{mast_id}/calibration/{id}',
      path: {
        'mast_id': mastId,
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
