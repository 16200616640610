import {
  Alert,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  TextInput,
} from '@patternfly/react-core'
import { useContext, useEffect, useState } from 'react'
import { Masts, MastsAPI } from '../../../client'
import { ActionState } from '../../../components/actionState'
import NumberInput from '../../../components/NumberInput'
import Select from '../../../components/selects/Select'
import PermissionContext from '../../../contexts/PermissionContext'
import { queryClient } from '../../../services/api'
import { toISOString } from '../../../utils/formatDate'
import { BINARY, CACHE_KEY_MAST_METADATA } from '../constants'

const REQUEST_STATE_MESSAGES = {
  idle: <></>,
  pending: <Alert variant='info' title='Processando' isInline />,
  success: <Alert variant='success' title='Salvo com sucesso' isInline />,
  failure: <Alert variant='danger' title='Ocorreu um erro ao processar o evento' isInline />,
}

const ModalMast = ({
  mast,
  isOpen,
  onClose,
  activeMast,
}: {
  mast: Masts
  isOpen: boolean
  onClose: () => void
  activeMast: (m: Masts) => void
}) => {
  const [datasetDescription, setDatasetDescription] = useState(mast.dataset_description)
  const [height, setHeight] = useState(mast.height)
  const [elevation, setElevation] = useState(mast.elevation)
  const [activeDataloggerSerial, setActiveDataloggerSerial] = useState(
    mast.active_datalogger_serial
  )
  const [filenameFormat, setFilenameFormat] = useState(mast.filename_format)
  const [pasta, setPasta] = useState(mast.pasta)
  const [tipoDatalogger, setTipoDatalogger] = useState(mast.tipo_datalogger)
  const [tipoFiltro, setTipoFiltro] = useState(mast.tipo_filtro)
  const [filtro, setFiltro] = useState(mast.filtro)
  const [windogReady, setWindogReady] = useState<{ name: string }>(BINARY[0])
  const [solar, setSolar] = useState<{ name: string }>(BINARY[1])

  useEffect(() => {
    setDatasetDescription(mast.dataset_description)
    setHeight(mast.height)
    setElevation(mast.elevation)
    setActiveDataloggerSerial(mast.active_datalogger_serial)
    setFilenameFormat(mast.filename_format)
    setPasta(mast.pasta)
    setTipoDatalogger(mast.tipo_datalogger)
    setTipoFiltro(mast.tipo_filtro)
    setFiltro(mast.filtro)
    setWindogReady(BINARY[0])
    setSolar(BINARY[1])
  }, [mast.id])

  const [requestState, setRequestState] = useState<ActionState>('idle')
  const { me } = useContext(PermissionContext)
  const [alertActive, setAlertActive] = useState(false)

  const closeModal = (updatedMast: Masts) => {
    const timerCloseModal = setTimeout(() => {
      setAlertActive(false)
      setRequestState('idle')
      activeMast(updatedMast)
      onClose()
    }, 750)
    return () => clearTimeout(timerCloseModal)
  }

  const handleSubmit = async () => {
    if (
      datasetDescription === '' ||
      height === undefined ||
      elevation === undefined ||
      activeDataloggerSerial === '' ||
      filenameFormat === '' ||
      pasta === '' ||
      tipoDatalogger === '' ||
      tipoFiltro === '' ||
      filtro === ''
    ) {
      setAlertActive(true)
      return
    }
    setRequestState('pending')
    const requestBodyUpdate = {
      id: mast.id,
      name: mast.name,
      timestep: mast.timestep,
      dataset_start: mast.dataset_start,
      dataset_end: mast.dataset_end,
      dataset_inst_ht_meas_units: mast.dataset_inst_ht_meas_units,
      dataset_description: datasetDescription,
      height: height,
      elevation: elevation,
      elevation_units: mast?.elevation_units,
      lat: mast.lat,
      lon: mast.lon,
      site_id: mast.site_id,
      active_datalogger_serial: activeDataloggerSerial,
      filename_format: filenameFormat,
      pasta: pasta,
      tipo_datalogger: tipoDatalogger,
      tipo_filtro: tipoFiltro,
      filtro: filtro,
      windog_ready: windogReady.name === 'Sim' ? 'true' : 'false',
      solar: solar.name === 'Sim' ? 'true' : 'false',
      last_modified: toISOString(new Date()),
      created_at: mast.created_at,
      username: me.current_user.username,
    }
    const requestMethod = MastsAPI.updateMast

    try {
      await requestMethod({
        mastId: mast.id,
        requestBody: requestBodyUpdate,
      })
    } catch (error: any) {
      setRequestState('failure')
      setAlertActive(true)
      return
    }

    queryClient.invalidateQueries([CACHE_KEY_MAST_METADATA])

    setRequestState('success')
    closeModal(requestBodyUpdate)
  }

  useEffect(() => {
    setAlertActive(false)
    setRequestState('idle')
  }, [
    mast,
    datasetDescription,
    height,
    elevation,
    activeDataloggerSerial,
    filenameFormat,
    pasta,
    tipoDatalogger,
    tipoFiltro,
    filtro,
    windogReady,
    solar,
  ])

  const handleHeightChange = (newHeight: number) => {
    setHeight(newHeight)
  }

  const handleElevationChange = (newElevation: number) => {
    setElevation(newElevation)
  }

  return (
    <Modal
      variant={ModalVariant.medium}
      title={`Editar ${mast.name}`}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button
          key='update'
          variant='primary'
          form='modal-with-form-form'
          onClick={handleSubmit}
          isBlock
        >
          Salvar
        </Button>,
        <Button key='cancelar' variant='danger' isDanger onClick={onClose} isBlock>
          Cancelar
        </Button>,
      ]}
    >
      <Grid hasGutter>
        <GridItem sm={12} md={6}>
          <span>Descrição</span>
          <TextInput
            isRequired
            type='text'
            id='description-text-input-modal-mast'
            value={datasetDescription ? datasetDescription : ''}
            onChange={(_, value) => setDatasetDescription(value)}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>Altura</span>
          <NumberInput value={height ? height : 0} onChange={handleHeightChange} />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>Elevação</span>
          <NumberInput value={elevation ? elevation : 0} onChange={handleElevationChange} />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>N. Serie Datalogger</span>
          <TextInput
            isRequired
            type='text'
            id='activeDataloggerSerial-text-input-modal-mast'
            value={activeDataloggerSerial ? activeDataloggerSerial : ''}
            onChange={(_, value) => setActiveDataloggerSerial(value)}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>Tipo Datalogger</span>
          <TextInput
            isRequired
            type='text'
            id='tipoDatalogger-text-input-modal-mast'
            value={tipoDatalogger ? tipoDatalogger : ''}
            onChange={(_, value) => setTipoDatalogger(value)}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>Tipo Filtro Email</span>
          <TextInput
            isRequired
            type='text'
            id='tipoFiltro-text-input-modal-mast'
            value={tipoFiltro ? tipoFiltro : ''}
            onChange={(_, value) => setTipoFiltro(value)}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>Formato do Arquivo</span>
          <TextInput
            isRequired
            type='text'
            id='tipoFiltro-text-input-modal-mast'
            value={filenameFormat ? filenameFormat : ''}
            onChange={(_, value) => setFilenameFormat(value)}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>Pasta</span>
          <TextInput
            isRequired
            type='text'
            id='pasta-text-input-modal-mast'
            value={pasta ? pasta : ''}
            onChange={(_, value) => setPasta(value)}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>Valor Filtro Email</span>
          <TextInput
            isRequired
            type='text'
            id='filtro-text-input-modal-mast'
            value={filtro ? filtro : ''}
            onChange={(_, value) => setFiltro(value)}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>Windog Ready</span>
          <Select<{ name: string }>
            items={BINARY}
            itemKeyName='name'
            itemValueName='name'
            selected={windogReady}
            onChange={e => setWindogReady(e)}
            selectProps={{ menuAppendTo: 'parent' }}
          />
        </GridItem>
        <GridItem sm={12} md={6}>
          <span>Solar</span>
          <Select<{ name: string }>
            items={BINARY}
            itemKeyName='name'
            itemValueName='name'
            selected={solar}
            onChange={e => setSolar(e)}
            selectProps={{ menuAppendTo: 'parent' }}
          />
        </GridItem>
      </Grid>

      {REQUEST_STATE_MESSAGES[requestState]}
      {alertActive ? (
        <>
          {datasetDescription === '' ||
          height === undefined ||
          elevation === undefined ||
          activeDataloggerSerial === '' ||
          filenameFormat === '' ||
          pasta === '' ||
          tipoDatalogger === '' ||
          tipoFiltro === '' ||
          filtro === '' ? (
            <Alert
              variant='danger'
              className='pf-v5-u-my-sm'
              isInline
              title='Preencha o(s) campo(s) faltante(s)'
            />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </Modal>
  )
}

export default ModalMast
