import {
  Alert,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Split,
  SplitItem,
  Stack,
  StackItem,
  Text,
  TextArea,
  TextContent,
  TextVariants,
  Tile,
  ValidatedOptions,
} from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useMemo, useState } from 'react'
import {
  AvailabilityAPI,
  AvailabilityPCReclassification,
  AvailabilityPowerCurveEvents,
  DowntimeComponentes,
  DowntimeTipos,
} from '../../client'
import { ActionState } from '../../components/actionState'
import Select from '../../components/selects/Select'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import { CACHE_KEY_PC, Responsabilidade, RESPONSABILIDADES } from './constants'
import PlotPowerCurveReclassification from './PlotPowerCurveReclassification'
import useData, { keyFromDate } from './useData'

interface ReclassificationTipos extends DowntimeTipos {
  id: number
  label_categoria: string
}

const ModalPCReclassification = ({
  isOpen,
  handleModalToggle,
  dataToUpdate,
  actionState = 'idle',
  pendingMessage = 'Salvando alterações',
  successMessage = 'Alterações salvas com sucesso',
  failureMessage = 'Ocorreu um erro ao salvar alterações',
  selectedDate,
  handleDeleteClick,
  modalConfirmation,
}: {
  isOpen: boolean
  handleModalToggle: () => void
  dataToUpdate: AvailabilityPowerCurveEvents
  additionalInformation?: React.ReactNode
  actionState: ActionState
  alertMessage?: string
  pendingMessage?: string
  successMessage?: string
  failureMessage?: string
  selectedDate: Date
  handleDeleteClick?: (item: string) => void
  modalConfirmation: React.ReactNode
}) => {
  const { componentes, tipos, dataIsLoading } = useData(selectedDate)

  const { site } = useContext(SitesContext)

  const tiposSelect: ReclassificationTipos[] = useMemo(() => {
    return tipos.map(tipo => {
      return { ...tipo, label_categoria: `${tipo.tipo} - ${tipo.categoria}` }
    })
  }, [tipos, dataIsLoading])

  const [selectedTipo, setSelectedTipo] = useState<ReclassificationTipos>(
    tiposSelect.find(t => t.id === dataToUpdate?.tipo_id) ?? tiposSelect[0]
  )

  const [selectedComponent, setSelectedComponent] = useState<DowntimeComponentes>(
    componentes.find(item => item.id === dataToUpdate?.component_id) ?? componentes[0]
  )

  const [responsabilidade, setResponsabilidade] = useState<Responsabilidade>(RESPONSABILIDADES[1])

  const [comment, setComment] = useState('')

  useEffect(() => {
    if (dataToUpdate) {
      if (tiposSelect.length) {
        setSelectedTipo(tiposSelect.find(t => t.id === dataToUpdate?.tipo_id) ?? tiposSelect[0])
      }

      if (componentes.length) {
        setSelectedComponent(
          componentes.find(item => item.id === dataToUpdate?.component_id) ?? componentes[0]
        )
      }
      setComment(dataToUpdate?.comment || '')
      setResponsabilidade(
        RESPONSABILIDADES.find(r => dataToUpdate?.responsabilidade_re === r.id) ??
          RESPONSABILIDADES[1]
      )
    }
  }, [tiposSelect, dataToUpdate])

  const [alertActive, setAlertActive] = useState(false)
  const [isError406, setIsError406] = useState(false)

  const [requestState, setRequestState] = useState<ActionState>('idle')

  const alertMessages = {
    idle: <></>,
    pending: <Alert variant='info' title={pendingMessage} isInline />,
    success: <Alert variant='success' title={successMessage} isInline />,
    failure: <Alert variant='danger' title={failureMessage} isInline />,
  }

  const handleSubmit = async () => {
    if (comment === '') {
      setAlertActive(true)
      return
    }
    setRequestState('pending')
    if (dataToUpdate) {
      const requestBody: AvailabilityPCReclassification = {
        ...dataToUpdate,
        site_id: site.site_id,
        availability_category: dataToUpdate?.availability_category,
        username: dataToUpdate?.username,
        comment,
        ts_in: dataToUpdate?.ts_in,
        ts_fin: dataToUpdate?.ts_fin,
        tipo_id: selectedTipo.id,
        component_id: selectedComponent.id,
        ts_record: dataToUpdate?.ts_record || '',
        responsabilidade_re: responsabilidade.id,
        energia_perdida: dataToUpdate?.energia_perdida,
      }
      const requestMethod = AvailabilityAPI.updatePcReclassification
      try {
        await requestMethod({
          siteId: site.site_id,
          requestBody,
        })
      } catch (error: any) {
        console.log(error.status)
        setIsError406(error.status === 406)
        setRequestState('failure')
        setAlertActive(true)
        return
      }
      setRequestState('success')
      queryClient.invalidateQueries([CACHE_KEY_PC, site?.site_id, keyFromDate(selectedDate, 1)])
    }
  }

  useEffect(() => {
    if (comment.length) {
      setAlertActive(false)
    }
  }, [comment])

  const closeModal = () => {
    setAlertActive(false)
    setIsError406(false)
    setRequestState('idle')
    handleModalToggle()
  }

  useEffect(() => {
    if (requestState === 'success') {
      const timerCloseModalAero = setTimeout(() => {
        closeModal()
      }, 750)
      return () => clearTimeout(timerCloseModalAero)
    }
  }, [requestState])

  return (
    <Modal
      width={'95%'}
      variant={ModalVariant.large}
      title={`Cadastro Reclassificação - ${dataToUpdate?.id?.split('-')[0]}`}
      aria-label='modal-curva-potencia'
      id='modal-curva-potencia'
      isOpen={isOpen}
      onClose={handleModalToggle}
      actions={[
        <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
          <Split hasGutter>
            <SplitItem>
              <Button
                key='save'
                variant='primary'
                onClick={() => {
                  handleSubmit()
                  if (actionState === 'success') handleModalToggle()
                }}
              >
                Salvar
              </Button>
            </SplitItem>
            <SplitItem>
              <Button key='cancelar' variant='secondary' isDanger onClick={handleModalToggle}>
                Cancelar
              </Button>
            </SplitItem>
          </Split>
          {handleDeleteClick && dataToUpdate && (
            <Button
              variant='danger'
              isDanger
              onClick={() => {
                handleDeleteClick(dataToUpdate?.id as string)
              }}
            >
              Deletar
            </Button>
          )}
        </div>,
      ]}
    >
      {modalConfirmation}
      <Grid hasGutter>
        {/* Form */}
        <GridItem lg={4}>
          {alertMessages[requestState]}
          {alertActive && (
            <>
              {comment === '' && (
                <Alert
                  variant='danger'
                  className='pf-v5-u-my-sm'
                  isInline
                  title='Preencha o campo de comentário'
                />
              )}
              {isError406 && (
                <Alert
                  variant='danger'
                  isInline
                  className='pf-v5-u-my-sm'
                  title='Já existe um evento com essas características'
                />
              )}
            </>
          )}
          <Stack>
            <StackItem>
              <span>Tipo</span>
              <Select<ReclassificationTipos>
                items={tiposSelect}
                itemValueName={'label_categoria'}
                itemKeyName={'id'}
                selected={selectedTipo}
                enableFilter
                selectProps={{ menuAppendTo: 'parent' }}
                onChange={setSelectedTipo}
              />
            </StackItem>
            <Split>
              <SplitItem style={{ width: '100%' }}>
                <span>Componente</span>
                <Select<DowntimeComponentes>
                  items={componentes}
                  itemValueName={'component'}
                  itemKeyName={'id'}
                  selected={selectedComponent}
                  enableFilter
                  onChange={setSelectedComponent}
                  selectProps={{ menuAppendTo: 'parent' }}
                  maxHeight={400}
                />
              </SplitItem>
              <SplitItem style={{ width: '100%' }}>
                <span>Responsabilidade</span>
                <Select<Responsabilidade>
                  items={RESPONSABILIDADES}
                  itemValueName={'nome'}
                  itemKeyName={'id'}
                  selected={responsabilidade}
                  selectProps={{ menuAppendTo: 'parent' }}
                  onChange={setResponsabilidade}
                />
              </SplitItem>
            </Split>
            <span>Comentário</span>
            <TextArea
              placeholder='Comentário'
              height={100}
              type='text'
              aria-label='description input field'
              value={comment}
              onChange={(_, text) => setComment(text)}
              validated={
                comment === '' && alertActive ? ValidatedOptions.error : ValidatedOptions.default
              }
            />
          </Stack>
        </GridItem>
        {/* Graph */}
        <GridItem lg={8}>
          <PlotPowerCurveReclassification eventPC={dataToUpdate} />
        </GridItem>
      </Grid>
    </Modal>
  )
}

export default ModalPCReclassification
