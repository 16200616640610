import {
  Button,
  Drawer,
  DrawerActions,
  DrawerCloseButton,
  DrawerContent,
  DrawerContentBody,
  DrawerHead,
  DrawerPanelContent,
  EmptyState,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
  Grid,
  GridItem,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core'
import { SelectVariant } from '@patternfly/react-core/deprecated'
import { CogIcon, DownloadIcon } from '@patternfly/react-icons'
import { intersection } from 'd3-array'
import { useContext, useEffect, useState } from 'react'
import { ProductionTurbDataSignals as Signals, Turbines } from '../../client'
import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import MultiSelect from '../../components/selects/MultiSelect'
import SitesContext from '../../contexts/SitesContext'
import useVertical from '../../hooks/useVertical'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import Context, { Provider } from './Context'
import HelpContent from './HelpContent'
import HistogramPlot from './HistogramPlot'
import { ACTIONS } from './reducer'
import ScatterPlot from './ScatterPlot'
import SettingsPanel from './SettingsPanel'
import TimeSeriesPlot from './TimeSeriesPlot'

const Page = () => {
  const { isLoading: isLoadingSite } = useContext(SitesContext)

  const {
    dispatch,
    site,
    siteTurbs,
    turbsSelected,
    siteSignals,
    signals,
    signalsSelected,
    startDate,
    endDate,
    layout,
    maxTurbs,
    maxSignals,
    turbdataIsLoading,
    turbdataIsFetching,
    turbdata,
  } = useContext(Context)

  const isLoading = isLoadingSite || turbdataIsLoading

  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const [isSettingsOpen, setSettingsOpen] = useState(false)
  const [revision, setRevision] = useState(0)

  const dtNow = new Date()
  dtNow.setHours(0)
  dtNow.setSeconds(0)
  dtNow.setDate(dtNow.getDate() - 1)

  useEffect(() => {
    setRevision(revision + 1)
  }, [isNavOpen, layout])

  const handleSelectTurbs = (newSelects: Turbines[]) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_TURBS,
      payload: { turbsSelected: newSelects.map(t => t.turb_id) },
    })
  }
  const handleSelectSignals = (newSelects: Signals[]) => {
    dispatch({
      type: ACTIONS.SET_SELECTED_SIGNALS,
      payload: { signalsSelected: newSelects.map(s => s.signal_id) },
    })
  }
  const handleOnChangeStartDate = (date: Date) => {
    dispatch({ type: ACTIONS.SET_SELECTED_START_DATE, payload: { startDateSelected: date } })
  }
  const handleOnChangeEndDate = (date: Date) => {
    dispatch({ type: ACTIONS.SET_SELECTED_END_DATE, payload: { endDateSelected: date } })
  }
  const handleEnviar = () => {
    dispatch({ type: ACTIONS.TRIGGER_FETCH, payload: {} })
  }
  const signalsSelectedDownloaded = Array.from(
    intersection(
      signals.map(s => s.signal_id),
      signalsSelected
    )
  ).length
  return (
    <PageFrame
      pageName='Curva de Potência'
      siteId={site?.site_id}
      siteName={site?.site_name}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading || turbdataIsFetching || turbdata.length === 0}
      isContentLoading={turbdataIsFetching}
      helpContent={<HelpContent />}
      filters={[
        <SelectSite
          onChange={() => {
            dispatch({ type: ACTIONS.SWITCH_SITE, payload: {} })
          }}
          key='SelectSite'
          enableUrlParams
          overwriteQueryParams
        />,
        <MultiSelect
          key={`multi_select_turbs_${site?.site_id}_${isLoading}`}
          items={siteTurbs}
          itemValueName='name'
          itemKeyName='turb_id'
          setSelected={handleSelectTurbs}
          selected={
            turbsSelected
              .map(t => siteTurbs.find(s => s.turb_id === t))
              .filter(t => t) as Turbines[]
          }
          placeholderText='Aerogeradores'
          minSelections={1}
          maxSelections={maxTurbs}
          selectProps={{ variant: SelectVariant.checkbox }}
          enableUrlParams
        />,
        <MultiSelect
          key={`multi_select_signals_${site?.site_id}_${isLoading}`}
          items={siteSignals}
          itemValueName='description'
          itemKeyName='signal_id'
          setSelected={handleSelectSignals}
          selected={
            signalsSelected
              .map(s => siteSignals.find(sg => sg.signal_id === s))
              .filter(s => s) as Signals[]
          }
          placeholderText='Sinais'
          minSelections={2}
          maxSelections={maxSignals}
          selectProps={{ variant: SelectVariant.checkbox }}
          enableUrlParams
        />,
        <DatetimeRangePicker
          value={{ startDate, endDate }}
          onChange={(newStart: Date, newEnd: Date) => {
            handleOnChangeStartDate(newStart)
            handleOnChangeEndDate(newEnd)
          }}
          key={`DatetimeRangePicker_date${site?.site_id}_${isLoading}`}
          calendarVariant='date'
          maxEnabled={dtNow}
          enableUrlParams
          variant='simple'
        />,
        <Button
          key='enviar_btn'
          variant={signalsSelectedDownloaded >= 2 ? 'control' : 'primary'}
          className='pf-v5-u-m-sm'
          onClick={handleEnviar}
        >
          <DownloadIcon /> Atualizar
        </Button>,
        <Button
          key='settings_btn'
          className='pf-v5-u-m-sm pf-v5-u-display-block-on-sm pf-v5-u-display-none'
          variant='control'
          onClick={() => setSettingsOpen(!isSettingsOpen)}
        >
          <CogIcon />
        </Button>,
      ]}
    >
      <Drawer isExpanded={isSettingsOpen} className='pf-v5-u-box-shadow-sm'>
        <DrawerContent
          panelContent={
            <DrawerPanelContent>
              <DrawerHead>
                <TextContent>
                  <Text component={TextVariants.h1} className='pf-v5-u-m-sm'>
                    Configurações
                  </Text>
                </TextContent>
                <DrawerActions>
                  <DrawerCloseButton onClick={() => setSettingsOpen(false)} />
                </DrawerActions>
              </DrawerHead>
              <SettingsPanel />
            </DrawerPanelContent>
          }
        >
          <DrawerContentBody>
            {signalsSelectedDownloaded >= 2 ? (
              <Grid>
                {layout === 1 && (
                  <>
                    <GridItem lg={6} md={12}>
                      <ScatterPlot
                        revision={Number(isNavOpen) + 0}
                        style={{
                          width: '100%',
                          height: calcVerticalHeight({ gridSize: 2, elementSize: 1 }),
                        }}
                      />
                    </GridItem>
                    <HistogramPlot
                      style={{
                        width: '100%',
                        height: calcVerticalHeight({ gridSize: 2, elementSize: 1 }),
                      }}
                    />
                    <GridItem lg={12} md={12}>
                      <TimeSeriesPlot
                        revision={Number(isNavOpen) + 0}
                        style={{
                          width: '100%',
                          height: calcVerticalHeight({ gridSize: 2, elementSize: 1 }),
                        }}
                      />
                    </GridItem>
                  </>
                )}
                {layout === 2 && (
                  <>
                    <GridItem lg={6} md={12}>
                      <ScatterPlot
                        revision={Number(isNavOpen) + 0}
                        style={{
                          width: '100%',
                          height: calcVerticalHeight({ gridSize: 2, elementSize: 2 }),
                        }}
                      />
                    </GridItem>
                    <GridItem lg={6} md={12}>
                      <TimeSeriesPlot
                        revision={Number(isNavOpen) + 0}
                        style={{
                          width: '100%',
                          height: calcVerticalHeight({ gridSize: 2, elementSize: 1 }),
                        }}
                      />
                      <Grid>
                        <HistogramPlot
                          wrapperGridSize={12}
                          style={{
                            width: '100%',
                            height: calcVerticalHeight({ gridSize: 2, elementSize: 1 }),
                          }}
                        />
                      </Grid>
                    </GridItem>
                  </>
                )}
                {layout === 3 && (
                  <>
                    <GridItem md={12}>
                      <ScatterPlot
                        revision={Number(isNavOpen) + 0}
                        style={{
                          width: '100%',
                          height: calcVerticalHeight({ gridSize: 1, elementSize: 1 }),
                        }}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <TimeSeriesPlot
                        revision={Number(isNavOpen) + 0}
                        style={{
                          width: '100%',
                          height: calcVerticalHeight({ gridSize: 1, elementSize: 1 }),
                        }}
                      />
                    </GridItem>
                    <HistogramPlot
                      maxPlots={5}
                      wrapperGridSize={12}
                      style={{
                        width: '100%',
                        height: calcVerticalHeight({ gridSize: 1, elementSize: 1 }),
                      }}
                    />
                  </>
                )}
              </Grid>
            ) : (
              <EmptyState variant={EmptyStateVariant.full}>
                <EmptyStateHeader
                  titleText='Clique em Atualizar'
                  icon={<EmptyStateIcon icon={DownloadIcon} />}
                  headingLevel='h1'
                />
                <EmptyStateBody>
                  Os dados selecionados ainda não foram baixados para o navegador. Clique em
                  Atualizar para baixa-los.
                </EmptyStateBody>
                <EmptyStateFooter>
                  <Button variant='primary' className='pf-v5-u-m-xl' onClick={handleEnviar}>
                    <DownloadIcon /> Atualizar
                  </Button>
                </EmptyStateFooter>
              </EmptyState>
            )}
          </DrawerContentBody>
        </DrawerContent>
      </Drawer>
    </PageFrame>
  )
}

const CurvaPotencia = () => (
  <Provider>
    <Page />
  </Provider>
)

export default CurvaPotencia
