import { Stack, StackItem } from '@patternfly/react-core'
import { useContext, useEffect, useMemo, useState } from 'react'

import SitesContext from '../../contexts/SitesContext'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import useData from './useData'

import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import { categories, CATEGORIES_DEFAULTS } from './constants'
import PanelSite from './PanelSite'
import PanelTurb from './PanelTurb'
import PanelWindFarm from './PanelWindFarm'
import SelectCategories from './SelectCategories'

const TimeAvailabilityPeriod = () => {
  const { site, isLoading: sitesIsLoading } = useContext(SitesContext)

  const [revision, setRevision] = useState(1)

  const [categorySelection, setCategorySelection] = useState<categories>(CATEGORIES_DEFAULTS)

  const date = new Date()
  const defaultStart = new Date(date.getFullYear(), date.getMonth(), 1)
  const defaultEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const [dateIn, setDateIn] = useState(defaultStart)
  const [dateFin, setDateFin] = useState(defaultEnd)

  const minDate = new Date(site.commissioning_date)

  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const { isLoading: isContentLoading } = useData(dateIn, dateFin)

  const handleSelectDate = (newStart: Date, newEnd: Date) => {
    setDateIn(newStart)
    setDateFin(newEnd)
  }

  useEffect(() => {
    setRevision(revision + 1)
  }, [isNavOpen, site, sitesIsLoading])

  return (
    <PageFrame
      pageName='Disponibilidade Temporal'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={sitesIsLoading}
      isContentLoading={isContentLoading}
      filters={[
        <SelectCategories
          categorySelection={categorySelection}
          setCategorySelection={setCategorySelection}
          key='SelectCategories'
        />,
        <SelectSite key='SelectSite' enableUrlParams />,
        <DatetimeRangePicker
          key='DatetimeMonthPicker'
          value={{ startDate: dateIn, endDate: dateFin }}
          maxEnabled={date}
          minEnabled={minDate}
          calendarVariant='date'
          onChange={handleSelectDate}
        />,
      ]}
    >
      <Stack hasGutter>
        <StackItem>
          <PanelSite
            isLoading={sitesIsLoading}
            dateIn={dateIn}
            dateFin={dateFin}
            revision={revision}
            categorySelection={categorySelection}
          />
        </StackItem>
        <StackItem>
          <PanelWindFarm
            dateIn={dateIn}
            dateFin={dateFin}
            revision={revision}
            categorySelection={categorySelection}
          />
        </StackItem>
        <StackItem>
          <PanelTurb
            dateIn={dateIn}
            dateFin={dateFin}
            revision={revision}
            categorySelection={categorySelection}
          />
        </StackItem>
      </Stack>
    </PageFrame>
  )
}

export default TimeAvailabilityPeriod
