/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TurbinesHealth } from '../models/TurbinesHealth';
import type { TurbinesHealthEvent } from '../models/TurbinesHealthEvent';
import type { TurbinesHealthFeatures } from '../models/TurbinesHealthFeatures';
import type { TurbinesHealthHeatmap } from '../models/TurbinesHealthHeatmap';
import type { TurbinesHealthSummary } from '../models/TurbinesHealthSummary';
import type { TurbineSystems } from '../models/TurbineSystems';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TurbineHealthAPI {

  /**
   * Get Turbine Systems
   * @returns TurbineSystems Successful Response
   * @throws ApiError
   */
  public static getTurbineSystems(): CancelablePromise<Array<TurbineSystems>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/turbines/system',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Turbine Health
   * @returns TurbinesHealth Successful Response
   * @throws ApiError
   */
  public static getTurbineHealth({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<TurbinesHealth>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/turbines/health',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Turbine Health Heatmap
   * @returns TurbinesHealthHeatmap Successful Response
   * @throws ApiError
   */
  public static getTurbineHealthHeatmap({
    siteId,
    turbId,
    systemId,
    tsIn,
    tsFin,
    deltaDays = 15,
  }: {
    siteId: number,
    turbId: number,
    systemId: number,
    tsIn?: string,
    tsFin?: string,
    deltaDays?: number,
  }): CancelablePromise<Array<TurbinesHealthHeatmap>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/turbines/health/heatmap',
      path: {
        'site_id': siteId,
      },
      query: {
        'turb_id': turbId,
        'system_id': systemId,
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'delta_days': deltaDays,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Turbine Health Summary
   * @returns TurbinesHealthSummary Successful Response
   * @throws ApiError
   */
  public static getTurbineHealthSummary({
    siteId,
    turbId,
    systemId,
  }: {
    siteId: number,
    turbId: number,
    systemId: number,
  }): CancelablePromise<Array<TurbinesHealthSummary>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/turbines/health/summary',
      path: {
        'site_id': siteId,
      },
      query: {
        'turb_id': turbId,
        'system_id': systemId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Turbine Health Events
   * @returns TurbinesHealthEvent Successful Response
   * @throws ApiError
   */
  public static getTurbineHealthEvents({
    siteId,
    turbId,
    systemId,
    tsIn,
    tsFin,
    deltaDays = 15,
  }: {
    siteId: number,
    turbId?: number,
    systemId?: number,
    tsIn?: string,
    tsFin?: string,
    deltaDays?: number,
  }): CancelablePromise<Array<TurbinesHealthEvent>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/turbines/health/events',
      path: {
        'site_id': siteId,
      },
      query: {
        'turb_id': turbId,
        'system_id': systemId,
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'delta_days': deltaDays,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Turbine Health Features
   * @returns TurbinesHealthFeatures Successful Response
   * @throws ApiError
   */
  public static getTurbineHealthFeatures({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<TurbinesHealthFeatures>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/turbines/health/features',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
