import { Grid, GridItem, Panel, PanelMain, PanelMainBody } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import {
  AvailabilityMonthly,
  AvailabilityParkMonthly,
  AvailabilityTurbineMonthly,
  Site,
} from '../../client'
import DatetimeMonthPicker from '../../components/calendar/DatetimeMonthPicker'
import SitesContext from '../../contexts/SitesContext'
import api, { queryClient } from '../../services/api'
import DownloadButton from '../shared/DownloadButton'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import ChartAvailableEnergyMonthly from './ChartAvailableEnergyMonthly'
import ChartAvailableEnergyParks from './ChartAvailableEnergyParks'
import ChartAvailableWindTurbinesMonthly from './ChartAvailableWindTurbinesMonthly'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

const get_data_monthly = async (site: Site) => {
  if (!site || !site?.site_id) return []

  const response = await api.get(`sites/${site.site_id}/availability/monthly`)
  const { data } = response
  return data.sort((a: AvailabilityMonthly, b: AvailabilityMonthly) => a.mm - b.mm)
}

const get_data_parks = async (site: Site, farmSelected: number) => {
  if (!site || !site?.site_id) return []

  const response = await api.get(
    `sites/${site.site_id}/availability/monthly/parks?wf_id=${farmSelected}`
  )
  const { data } = response
  return data.sort((a: AvailabilityParkMonthly, b: AvailabilityParkMonthly) => a.mm - b.mm)
}

const get_data_turbines_monthly = async (site: Site) => {
  if (!site || !site?.site_id) return []

  const response = await api.get(`sites/${site.site_id}/availability/monthly/turbines`)
  const { data } = response
  return data.sort((a: AvailabilityTurbineMonthly, b: AvailabilityTurbineMonthly) => a.mm - b.mm)
}

const prefetch = async (site: Site, farmSelected: number) => {
  await queryClient.prefetchQuery(
    ['availableEnergyMonthly', site.site_id],
    async () => get_data_monthly(site),
    { ...defaultQueryOptions }
  )

  await queryClient.prefetchQuery(
    ['availableEnergyParks', site.site_id],
    async () => get_data_parks(site, farmSelected),
    { ...defaultQueryOptions }
  )

  await queryClient.prefetchQuery(
    ['availableWindTurbinesMonthly', site.site_id],
    async () => get_data_turbines_monthly(site),
    { ...defaultQueryOptions }
  )
}

const EnergyAvailability = () => {
  const { site, sites, turbs, isLoading: sitesIsLoading, windFarms } = useContext(SitesContext)
  const farmSelected = 0

  const minDate = new Date(site.commissioning_date)
  const dtNow = new Date()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const queryOptions = { ...defaultQueryOptions, enabled: !!site?.site_id }

  const { isLoading: isDataMonthlyLoading, data: dataMonthly } = useQuery(
    ['availableEnergyMonthly', site?.site_id],
    async () => get_data_monthly(site),
    queryOptions
  )

  const { isLoading: isDataParksLoading, data: dataParks } = useQuery(
    ['availableEnergyParks', site?.site_id],
    async () => get_data_parks(site, farmSelected),
    queryOptions
  )

  const { isLoading: isTurbineMonthlyLoading, data: dataTurbinesMonthly } = useQuery(
    ['availableWindTurbinesMonthly', site?.site_id],
    async () => get_data_turbines_monthly(site),
    queryOptions
  )

  const isLoading =
    isDataMonthlyLoading || isDataParksLoading || isTurbineMonthlyLoading || sitesIsLoading

  sites?.filter(s => s.site_id !== site.site_id).forEach(site => prefetch(site, farmSelected))

  return (
    <PageFrame
      pageName='Disponibilidade Energética'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      filters={[
        <SelectSite key='f1' enableUrlParams overwriteQueryParams />,
        <DatetimeMonthPicker
          key='DatetimeMonthPicker'
          date={selectedDate}
          maxEnabled={dtNow}
          minEnabled={minDate}
          onChange={setSelectedDate}
        />,

        <DownloadButton
          key={`dl_btn_${site?.site_name}`}
          label='Exportar'
          url={`/sites/${site.site_id}/availability/report?${new URLSearchParams({
            mm: String(selectedDate.getMonth() + 1),
            yyyy: String(selectedDate.getFullYear()),
          })}`}
          filename={`report_availability_${site.site_name}_${selectedDate.getFullYear()}_${
            selectedDate.getMonth() + 1
          }.xlsx`}
        />,
      ]}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelMain>
          <PanelMainBody>
            <Grid hasGutter>
              <GridItem lg={4} md={12}>
                {!isLoading && (
                  <ChartAvailableEnergyMonthly
                    dataChart={dataMonthly}
                    yearSelected={selectedDate.getFullYear()}
                    isLoading={isLoading}
                    revision={(isNavOpen as unknown as number) + 0}
                  />
                )}
                {!isLoading && (
                  <ChartAvailableEnergyParks
                    dataChart={dataParks}
                    windFarms={windFarms}
                    yearSelected={selectedDate.getFullYear()}
                    monthSelected={selectedDate.getMonth() + 1}
                    isLoading={isLoading}
                    revision={(isNavOpen as unknown as number) + 0}
                  />
                )}
              </GridItem>
              <GridItem lg={8} md={12}>
                {!isLoading && (
                  <ChartAvailableWindTurbinesMonthly
                    dataChart={dataTurbinesMonthly}
                    yearSelected={selectedDate.getFullYear()}
                    monthSelected={selectedDate.getMonth() + 1}
                    turbs={turbs}
                    isLoading={isLoading}
                    revision={(isNavOpen as unknown as number) + 0}
                  />
                )}
              </GridItem>
            </Grid>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </PageFrame>
  )
}

export default EnergyAvailability
