import { Marker } from '@react-google-maps/api'
import { towerMapType } from '.'
import tower_icon from '../../assets/img/tower_icon.png'
import tower_icon_solar from '../../assets/img/tower_icon_solar.png'
import { TowerIdentifier } from '../../client'

const Torres = ({
  towersIsLoading,
  towers,
  showTowers,
  showTowersAma,
  site_id,
  towerMap,
}: {
  towersIsLoading: boolean
  showTowers: boolean
  showTowersAma: boolean
  site_id: number
  towers?: TowerIdentifier[]
  towerMap: towerMapType
}) => {
  const iconSize = new google.maps.Size(40, 40)

  const towerIcon = {
    url: tower_icon,
    size: iconSize,
    scaledSize: iconSize,
  }

  const solarIcon = {
    url: tower_icon_solar,
    size: iconSize,
    scaledSize: iconSize,
  }

  if (towersIsLoading || !towers) return null

  return (
    <>
      {towers
        .map(tower => {
          const isTower = tower.site_id !== site_id
          const isTowerAma = tower.site_id === site_id
          const config = towerMap[tower.tower_id]
          const [visible, solar] = [config?.visible, config?.solar]

          if (
            (isTower && showTowers && !!visible !== false) ||
            (isTowerAma && showTowersAma && !!visible !== false)
          )
            return (
              <Marker
                key={`${tower.tower_id}-${tower.tower_name}`}
                position={{ lat: tower.lat, lng: tower.lon }}
                icon={solar ? solarIcon : towerIcon}
                title={`${tower.tower_name}`}
              />
            )

          return null
        })
        .filter(e => e)}
    </>
  )
}

export default Torres
