import { Button, Form, FormGroup, Modal, ModalVariant, TextArea } from '@patternfly/react-core'
import { EditIcon, Remove2Icon } from '@patternfly/react-icons'
import {
  Caption,
  Table /* data-codemods */,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { useEffect, useState } from 'react'
import '../../assets/css/styles.css'
import { News, NewsAPI } from '../../client'
import PageFrame from '../shared/Page'

const NewsModal = ({
  handleModalToggle,
  handleSubmit,
  isModalOpen,
  selectedNews,
  setSelectedNews,
}: {
  handleModalToggle: () => void
  handleSubmit: (action: 'Editar' | 'Criar', _selectedNews: News) => Promise<void>
  isModalOpen: boolean
  selectedNews: undefined | News
  setSelectedNews: React.Dispatch<React.SetStateAction<undefined | News>>
}) => {
  const action = selectedNews !== undefined && selectedNews?.news_id ? 'Editar' : 'Criar'
  const news_date = new Date().toISOString().split('T')[0]

  return (
    <Modal
      aria-label='create novidade'
      variant={ModalVariant.large}
      title={`${action} Novidade`}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button
          key='create'
          variant='primary'
          form='modal-with-form-form'
          onClick={() => {
            if (!selectedNews?.news_text) return
            handleSubmit(action, selectedNews)
            handleModalToggle()
          }}
        >
          Enviar
        </Button>,
        <Button key='cancel' variant='secondary' isDanger onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      <Form id='modal-with-form-form'>
        <FormGroup>
          <TextArea
            style={{ height: 300 }}
            resizeOrientation='vertical'
            required
            value={selectedNews?.news_text}
            onChange={(_, text) => setSelectedNews({ ...selectedNews, news_text: text, news_date })}
            aria-label='text area'
          />
        </FormGroup>
      </Form>
    </Modal>
  )
}

const Novidades = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const [isModalOpen, setModalOpen] = useState(false)

  const [news, setNews] = useState<News[]>([])

  const [selectedNews, setSelectedNews] = useState<News>()

  const handleModalToggle = () => {
    setSelectedNews(undefined)
    setModalOpen(!isModalOpen)
  }

  const getNews = async () => {
    NewsAPI.getAll({ limit: 0 })
      .then(r => setNews(r))
      .catch(e => console.log(e))
  }

  const handleErr = (e: string) => {
    console.log(e)
    getNews()
  }

  const deleteNews = async (n: News) => {
    if (!n.news_id) return console.log('Novidade selecionada não possui identificação')

    await NewsAPI.delete({ newsId: n.news_id })
      .then(newsId => setNews(news.filter(ne => ne.news_id !== newsId)))
      .catch(e => handleErr(e))
  }

  const createNews = async (n: News) => {
    NewsAPI.create({ requestBody: n })
      .then(r => setNews([...news, r]))
      .catch(e => handleErr(e))
  }

  const updateNews = async (n: News) => {
    NewsAPI.update({ requestBody: n })
      .then(r => setNews([...news.filter(ne => ne.news_id !== r.news_id), r]))
      .catch(e => handleErr(e))
  }

  const handleEditUserClick = (n: News) => {
    setSelectedNews(n)
    setModalOpen(!isModalOpen)
  }
  const handleDeleteClick = (n: News) => deleteNews(n)

  const handleSubmit = (action: 'Editar' | 'Criar', _selectedNews: News) => {
    return { Criar: createNews, Editar: updateNews }[action](_selectedNews)
  }

  useEffect(() => {
    getNews()
  }, [])

  const formatDate = (str: string) => {
    return str.split('-').reverse().join('/')
  }

  const filters = [
    <Button
      key='criar-novidade'
      variant='control'
      className='pf-v5-u-m-sm'
      onClick={handleModalToggle}
    >
      Criar Novidade
    </Button>,
  ]

  return (
    <PageFrame pageName='Novidades' isNavOpen={isNavOpen} setNavOpen={setNavOpen} filters={filters}>
      <Table variant='compact' aria-label='Tabela Novidades' isStickyHeader>
        <Caption>Novidades</Caption>
        <Thead>
          <Tr>
            <Th>Conteúdo</Th>
            <Th>Data</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>

        <Tbody>
          {news
            ?.sort((a, b) => (a.news_date < b.news_date ? 1 : -1))
            .map((n, idx) => {
              return (
                <Tr key={idx}>
                  <Td dataLabel='Conteúdo'>{n.news_text}</Td>
                  <Td dataLabel='Data'>{formatDate(n.news_date)}</Td>
                  <Td dataLabel='Ações'>
                    <div className='column-icons'>
                      <span className='container-icon'>
                        <EditIcon onClick={() => handleEditUserClick(n)} />
                      </span>
                      <span className='container-icon'>
                        <Remove2Icon onClick={() => handleDeleteClick(n)} />
                      </span>
                    </div>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
      <NewsModal
        handleModalToggle={handleModalToggle}
        handleSubmit={handleSubmit}
        isModalOpen={isModalOpen}
        selectedNews={selectedNews}
        setSelectedNews={setSelectedNews}
      />
    </PageFrame>
  )
}

export default Novidades
