import { Stack, StackItem, Tab, Tabs, TabTitleText } from '@patternfly/react-core'
import { useContext, useEffect, useMemo, useState } from 'react'
import DatetimeMonthPicker from '../../components/calendar/DatetimeMonthPicker'
import PermissionContext from '../../contexts/PermissionContext'
import SitesContext from '../../contexts/SitesContext'
import PageFrame from '../shared/Page'
import TableAero from './TableAero'

import { useLocation, useNavigate } from 'react-router'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { getFilterParams, replaceFilterParams } from '../../utils/url'
import SelectSite from '../shared/selects/SelectSite'
import { GRID_SIZE, TAB_HEADER_HEIGHT } from './constants'
import PlotTimeline from './PlotTimeline'
import TableBOP from './TableBOP'
import TablePCReclassification from './TablePCReclassification'
import TableRestricoes from './TableRestricoes'
import useData from './useData'

const getDefaultDate = (dateParam: number[] | null) => {
  const dtNow = new Date()

  if (!dateParam || dateParam.length === 0) {
    return new Date(dtNow.getFullYear(), dtNow.getMonth(), 1)
  }

  return new Date(dateParam[0])
}

const ParadasRestricoes = () => {
  const { site, isLoading: siteIsLoading, commissioningDate } = useContext(SitesContext)
  const { meIsLoading } = useContext(PermissionContext)

  const date = new Date()

  const dateParam = getFilterParams('date')
  const location = useLocation()
  const navigate = useNavigate()

  const [selectedDate, setSelectedDate] = useState(getDefaultDate(dateParam))
  const [activeTabKey, setActiveTabKey] = useState<number | string>(1)
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const [timelineRevision, setTimelineRevision] = useState(0)

  useEffect(() => {
    const dt = getDefaultDate(dateParam)
    setSelectedDate(dt)
  }, [])

  useEffect(() => {
    setTimelineRevision(timelineRevision + 1)
  }, [isNavOpen, activeTabKey, site, siteIsLoading])

  const {
    dataIsLoading,
    downtimeOns,
    downtimeBop,
    downtimeAero,
    componentes,
    setores,
    tipos,
    pcReclassification,
  } = useData(selectedDate)

  const isLoading = [siteIsLoading, meIsLoading, dataIsLoading].some(l => l)

  const [resizeMode, setResizeMode] = useState<number>(0)

  const heightTop = useMemo(() => {
    return calcVerticalHeight({ elementSize: GRID_SIZE / 2 + resizeMode, gridSize: GRID_SIZE })
  }, [resizeMode])
  const heightBottom = useMemo(() => {
    return calcVerticalHeight({ elementSize: GRID_SIZE / 2 - resizeMode, gridSize: GRID_SIZE })
  }, [resizeMode])

  useEffect(() => {
    const getResizeStorage = localStorage.getItem('resizeParadasERestricoes')

    if (getResizeStorage) {
      const resize = Number(JSON.parse(getResizeStorage))
      setResizeMode(resize)
    }
  }, [])

  return (
    <PageFrame
      pageName='Paradas e Restrições'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      siteName={site?.site_name}
      siteId={site?.site_id}
      filters={[
        <SelectSite key='select_sites' enableUrlParams />,
        <DatetimeMonthPicker
          key={`select_date_${site?.site_name}_${isLoading}`}
          date={selectedDate}
          minEnabled={commissioningDate}
          maxEnabled={date}
          onChange={e => {
            setSelectedDate(e)
            const newLocation = replaceFilterParams(location, [e], 'date')
            navigate(newLocation)
          }}
        />,
      ]}
    >
      <Stack
        className='pf-v5-u-box-shadow-md'
        style={{
          height: calcVerticalHeight({ gridSize: GRID_SIZE, elementSize: GRID_SIZE }),
        }}
      >
        <StackItem isFilled style={{ backgroundColor: ColorScale.branco_clear, height: heightTop }}>
          <PlotTimeline
            revision={timelineRevision}
            selectedDate={selectedDate}
            dataIsLoading={dataIsLoading}
            downtimeAero={downtimeAero}
            downtimeOns={downtimeOns}
            downtimeBop={downtimeBop}
          />
        </StackItem>
        <StackItem
          isFilled
          style={{ backgroundColor: ColorScale.cinza_claro, height: heightBottom }}
        >
          {!isLoading && (
            <Tabs
              id='paradas-restricoes-main-tabs'
              activeKey={activeTabKey}
              onSelect={(_, tabIndex) => setActiveTabKey(tabIndex)}
              variant='default'
              isBox
              style={{ height: `${TAB_HEADER_HEIGHT}rem` }}
            >
              <Tab eventKey={0} title={<TabTitleText>Paradas BOP</TabTitleText>}>
                <TableBOP
                  selectedDate={selectedDate}
                  downtimeBop={downtimeBop}
                  tipos={tipos}
                  componentes={componentes}
                  setores={setores}
                  resizeMode={resizeMode}
                  setResizeMode={setResizeMode}
                />
              </Tab>
              <Tab eventKey={1} title={<TabTitleText>Paradas Aero</TabTitleText>}>
                <TableAero
                  selectedDate={selectedDate}
                  downtimeAero={downtimeAero}
                  componentes={componentes}
                  tipos={tipos}
                  resizeMode={resizeMode}
                  setResizeMode={setResizeMode}
                />
              </Tab>
              <Tab eventKey={2} title={<TabTitleText>Restrições ONS</TabTitleText>}>
                <TableRestricoes
                  selectedDate={selectedDate}
                  downtimeOns={downtimeOns}
                  resizeMode={resizeMode}
                  setResizeMode={setResizeMode}
                />
              </Tab>
              <Tab eventKey={3} title={<TabTitleText>Curva de Potência</TabTitleText>}>
                <TablePCReclassification
                  pcReclassification={pcReclassification}
                  selectedDate={selectedDate}
                  resizeMode={resizeMode}
                  setResizeMode={setResizeMode}
                />
              </Tab>
            </Tabs>
          )}
        </StackItem>
      </Stack>
    </PageFrame>
  )
}

export default ParadasRestricoes
