import React from 'react'
import SelectList from '../shared/selects/DeprecatedSelectList'

import {
  Button,
  Form,
  Modal,
  ModalVariant,
  Split,
  SplitItem,
  TextArea,
} from '@patternfly/react-core'

function ModalPageMaintenance(props) {
  const {
    isModalOpen,
    handleModalToggle,
    description,
    setDescription,
    pages,
    sites,
    selectedSite,
    setSelectedSite,
    setSelectedPage,
    selectedPage,
    modalInfo,
    selectedMainId,
  } = props

  const handleEnviar = () => {
    if (!selectedPage || !selectedSite || !description) return
    modalInfo.callback.mutate({
      pm_id: selectedMainId,
      page_id: selectedPage,
      site_id: selectedSite,
      description,
    })
    handleModalToggle({})
  }

  const selectSite = (
    <SelectList
      startSelected={selectedSite}
      onChange={setSelectedSite}
      isLoading={false}
      list={sites?.map(s => {
        return { value: s.site_id, label: s.site_name }
      })}
    />
  )
  const selectPage = (
    <SelectList
      startSelected={pages?.find(p => p.page_id === selectedPage)?.page_id}
      onChange={setSelectedPage}
      isLoading={false}
      list={pages?.map(p => {
        return { value: p.page_id, label: p.name }
      })}
    />
  )

  return (
    <Modal
      aria-label='create page maintenance'
      variant={ModalVariant.large}
      title={`${modalInfo.title} página em manutenção`}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button key='send' variant='primary' form='modal-with-form-form' onClick={handleEnviar}>
          Enviar
        </Button>,
        <Button key='cancelar' variant='secondary' isDanger onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      <Form id='modal-with-form-form'>
        <Split>
          <SplitItem>{selectSite}</SplitItem>
          <SplitItem isFilled />
          <SplitItem>{selectPage}</SplitItem>
        </Split>
        <TextArea
          resizeOrientation='vertical'
          value={description}
          onChange={setDescription}
          aria-label='description text area'
        />
      </Form>
    </Modal>
  )
}

export default ModalPageMaintenance
