import { useQuery } from '@tanstack/react-query'
import { createContext, ReactNode, useState } from 'react'
import { useParams } from 'react-router'
import { FlagTypes, Masts, MastsAPI, Sensors as BaseSensors, SignalTypes } from '../client'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: true,
  staleTime: 1000 * 60 * 60 * 60,
}

const sortById = (a: { id: number }, b: { id: number }) => a.id - b.id

const loadMetadata = async () => {
  const requests = [MastsAPI.getAll(), MastsAPI.getFlagTypes(), MastsAPI.getSignalTypes()]
  const [_masts, _flagTypes, _signalTypes] = await Promise.all(requests)

  const masts = _masts.sort(sortById) as Masts[]
  const flagTypes = _flagTypes.sort(sortById) as FlagTypes[]
  const signalTypes = _signalTypes.sort(sortById) as SignalTypes[]

  return { allMasts: masts, flagTypes, signalTypes }
}

export interface Sensors extends BaseSensors {
  label: string
}

interface Metadata {
  allMasts: Masts[]
  flagTypes: FlagTypes[]
  signalTypes: SignalTypes[]
}

interface IMastContext extends Metadata {
  mast: Masts
  isLoading: boolean
  setActiveMast: (mast: Masts) => void
  sensors: Sensors[]
  sensorsIsLoading: boolean
}

const CONTEXT_DEFAULTS: IMastContext = {
  mast: { id: 0, name: '', dataset_end: '2030-01-01' } as Masts,
  allMasts: new Array<Masts>(),
  flagTypes: new Array<FlagTypes>(),
  signalTypes: new Array<SignalTypes>(),
  isLoading: true,
  setActiveMast: (mast: Masts) => console.log(mast),
  sensors: new Array<Sensors>(),
  sensorsIsLoading: true,
}

const MastContext = createContext<IMastContext>(CONTEXT_DEFAULTS)

export const MastProvider = ({ children }: { children: ReactNode }) => {
  const { mastId } = useParams()

  const [initialized, setInitialized] = useState(false)
  const [mast, setMast] = useState<Masts>(CONTEXT_DEFAULTS.mast)

  const handleLoadMetadata = (data: Metadata) => {
    const { allMasts } = data

    if (allMasts.length > 0) {
      const _mast = allMasts.find(m => m.id === Number(mastId)) ?? allMasts[0]
      setMast(_mast)
    }

    setInitialized(true)
  }

  const { isLoading: metadataIsLoading, data: metadata } = useQuery(
    ['masts+metadata'],
    loadMetadata,
    {
      ...defaultQueryOptions,
      placeholderData: CONTEXT_DEFAULTS,
      onSuccess: handleLoadMetadata,
    }
  )

  const { isLoading: sensorsIsLoading, data: sensors } = useQuery(
    ['masts+sensors', mast.id],
    async () => {
      const _sensors = await MastsAPI.getSensors({ mastId: mast.id })
      return _sensors.map(s => {
        return { ...s, label: `${s.type}_${s.height}_${s.position}` }
      })
    },
    {
      ...defaultQueryOptions,
      placeholderData: CONTEXT_DEFAULTS.sensors,
    }
  )

  const isLoading = !initialized || metadataIsLoading

  const setActiveMast = (mast: Masts) => {
    if (metadataIsLoading) return console.error('Metadata is still loading')
    if (!metadata) return console.error('Metadata unavailable')

    setMast(mast)
  }
  const provides = {
    ...(metadata ?? CONTEXT_DEFAULTS),
    mast,
    isLoading,
    setActiveMast,
    sensors: sensors ?? CONTEXT_DEFAULTS.sensors,
    sensorsIsLoading,
  }
  return <MastContext.Provider value={provides}>{children}</MastContext.Provider>
}

export default MastContext
export type { IMastContext }
