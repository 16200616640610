import {
  Grid,
  GridItem,
  Panel,
  PanelMain,
  PanelMainBody,
  Tab,
  Tabs,
  TabTitleText,
} from '@patternfly/react-core'
import { useState } from 'react'
import PanelSickHeader from '../../components/PanelStickyHeader'
import { categories } from './constants'
import PlotDaily from './PlotDaily'
import PlotDailyTotal from './PlotDailyTotal'
import PlotMonthly from './PlotMonthly'
import PlotMonthlyTotal from './PlotMonthlyTotal'
import PlotToDate from './PlotToDate'
import PlotToDateTotal from './PlotToDateTotal'

const PanelSite = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
  categorySelection,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
  categorySelection: categories
}) => {
  const [activeTypeKey, setActiveTypeKey] = useState<string | number>('Total')

  return (
    <Panel className='pf-v5-u-box-shadow-sm'>
      <PanelMain>
        <PanelSickHeader title='Disponibilidade Temporal Por Site' />
        <PanelMainBody>
          <Tabs activeKey={activeTypeKey} onSelect={(_, tabIndex) => setActiveTypeKey(tabIndex)}>
            <Tab eventKey='Total' title={<TabTitleText> {'Total'} </TabTitleText>}></Tab>
            <Tab
              eventKey='Por Categoria'
              title={<TabTitleText> {'Por Categoria'} </TabTitleText>}
            ></Tab>
          </Tabs>

          <Grid hasGutter>
            <GridItem lg={8}>
              {activeTypeKey === 'Total' ? (
                <PlotMonthlyTotal
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotMonthly
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                />
              )}
            </GridItem>
            <GridItem lg={4}>
              {activeTypeKey === 'Total' ? (
                <PlotToDateTotal
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotToDate
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                />
              )}
            </GridItem>
            <GridItem>
              {activeTypeKey === 'Total' ? (
                <PlotDailyTotal
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotDaily
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                />
              )}
            </GridItem>
          </Grid>
        </PanelMainBody>
      </PanelMain>
    </Panel>
  )
}

export default PanelSite
